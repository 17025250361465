<template src="./sezioniDettaglio.html"></template>

<script>
import { get_disegnoDettaglio } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    sezioni: { type: Array, default: () => [] },
    datiDettaglio: { type: Object, default: () => ({}) },
  },

  data: () => ({
    attesa: false,

    quote: { x: [], y: [] },
    note: "",

    pathImg: "",
    attesaDisegno: false,

    avvisi: "",
  }),

  methods: {
    caricaDati() {
      if (this.dialog == false) return;

      // Reset
      this.quote.x = [];
      this.quote.y = [];

      this.quote.x[0] = this.datiDettaglio.QuotaX1;
      this.quote.x[1] = this.datiDettaglio.QuotaX2;
      this.quote.x[2] = this.datiDettaglio.QuotaX3;

      this.quote.y[0] = this.datiDettaglio.QuotaY1;
      this.quote.y[1] = this.datiDettaglio.QuotaY2;
      this.quote.y[2] = this.datiDettaglio.QuotaY3;

      this.avvisi = "";

      this.note = this.datiDettaglio.NoteQuote;

      this.caricaDisegno();
    },

    caricaDisegno() {
      this.avvisi = "";

      this.attesaDisegno = true;

      let param =
        "quote/" +
        this.datiDettaglio.IDPrev +
        "/" +
        this.datiDettaglio.Scheda +
        "/" +
        this.datiDettaglio.Prog +
        "/" +
        this.datiDettaglio.L +
        "/" +
        this.datiDettaglio.H +
        "/" +
        this.quote.x[0] +
        "/" +
        this.quote.x[1] +
        "/" +
        this.quote.x[2] +
        "/" +
        this.quote.y[0] +
        "/" +
        this.quote.y[1] +
        "/" +
        this.quote.y[2];

      get_disegnoDettaglio(param)
        .then((res) => {
          // Il disegno con le quote ha la desinenza Q
          let p = this.datiDettaglio.PathImg.replace(".png", "");
          p += "Q.png";

          // Aggiorno il disegno
          this.pathImg = p + "?" + Math.random();

          if (res.data != "OK") this.avvisi = res.data;

          this.attesaDisegno = false;
        })
        .catch((err) => {
          console.log(this.$i2kFunctions.getErrorMsg(err));
        });
    },

    salva() {
      // Controllo quote
      if (!this.checkQta()) return;

      this.chiudi(true);
    },

    checkQta() {
      // Verifico compilazione
      let contOri = 0;
      for (let i = 0; i <= this.getNrOri - 1; i++) {
        if (this.quote.x[i] != 0) contOri++;
      }

      let contVet = 0;
      for (let i = 0; i <= this.getNrVer - 1; i++) {
        if (this.quote.y[i] != 0) contVet++;
      }

      if (contOri < this.getNrOri || contVet < this.getNrVer) {
        // Messaggio
        this.$toast.info("Quote non impostate");
        return false;
      }

      return true;
    },

    chiudi(salva) {
      this.$emit("chiudiDialog", salva, this.quote, this.note);
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getNrOri() {
      return this.datiDettaglio.SezOri ? this.datiDettaglio.SezOri - 1 : 0;
    },
    getNrVer() {
      return this.datiDettaglio.SezVer ? this.datiDettaglio.SezVer - 1 : 0;
    },
  },

  watch: {
    dialog(val) {
      this.caricaDati();
    },
  },
};
</script>
