
import request from '@/services/interceptor.js'


// -----------------------------
// Clienti
// -----------------------------
export const get_Clienti = (par) => {
    return request({
        url: '/clienti/' + par,
        method: 'get',
        loadingBar: true
    })
};

export const set_VisitaClienti = (par) => {
    return request({
        url: '/clienti/visite',
        method: 'post',
        data: par
    })
};

export const del_VisitaClienti = (par) => {
    return request({
        url: '/clienti/visite/' + par,
        method: 'delete',
    })
};

export const get_stampaClienti = (par) => {
    return request({
        url: '/clienti/stampa/',
        method: 'post',
        data: par,
        responseType: 'blob',
        loadingBar: false
    })
};



