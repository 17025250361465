<template>
  <v-navigation-drawer
    fixed
    width="240"
    mobile-breakpoint="1024"
    app
    v-model="leftBarVis"
    :clipped="$vuetify.breakpoint.mdAndUp"
    v-if="menu.length > 0"
    color="#F5F6F7"
  >
    <v-list-item>
      <img
        src="~@/assets/img/logo_small.png"
        height="50"
        class="my-4 ml-4"
        color="primary"
      />
    </v-list-item>

    <v-divider></v-divider>

    <v-list shaped dense>
      <template v-for="item in menu">
        <v-list-item
          :key="item.MenuName"
          @click="gotoPage(item)"
          :style="{
            backgroundColor: checkRoute(item.Link) ? '#2665A4' : '#F5F6F7',
          }"
          class="pa-0 my-2"
          :class="checkRoute(item.Link) ? 'elevation-4' : ''"
          style="height: 55px !important"
        >
          <v-list-item-avatar>
            <v-icon :color="checkRoute(item.Link) ? 'white' : item.Color">{{
              item.Img
            }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-title
            class="font-weight-regular"
            style="font-size: 15px"
            :style="{ color: checkRoute(item.Link) ? 'white' : '#06163A' }"
            >{{ $store.getters["user/traduci"](item.Text) }}</v-list-item-title
          >

          <v-list-item-action
            v-if="item.MenuName == 'PreventiviNew'"
            style="width: 140px"
            class="mr-1"
          >
            <v-chip color="green" text-color="white">New</v-chip>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="esci"> <v-icon left>logout</v-icon>Logout </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>


<script>
import { get_cred } from "@/api/api.app.js";

export default {
  props: {
    menu: { type: Array, default: () => [] },
  },
  data: () => ({
    leftBarVis: null,
  }),

  methods: {
    esci() {
      this.$emit("esci");
    },

    gotoPage(item) {
      if (item.Link == "@prev") this.gotoPrev(item);
      else if (item.Link == "@esci") this.esci();
      else {
        this.$router.push({ path: item.Link });
      }
    },

    async gotoPrev(item) {
      try {
        // NOTA: serve per evitare il popup blocked dei browser
        let newTab = window.open();

        let res = await get_cred();

        let url = item.LinkParam + "?code=" + res.data;

        newTab.location.href = url;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    checkRoute(link) {
      return link === this.$route.path || link === this.$route.meta.pathLink;
    },
  },
};
</script>

