<template src="./insCliente.html"></template>

<script>
import { get_clientiOrdini, save_clientiOrdini } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    codCliente: { type: Number },
  },

  data: () => ({
    dati: {},
    dati_empty: {
      CodCliente: 0,
      RagSoc: "",
      Indirizzo: "",
      Cap: "",
      Localita: "",
      Provincia: "",
      Stato: "",
      CategoriaCliente: "",
      Cell: "",
      Fax: "",
      Telefono: "",
      Sconto: "",
      Mail: "",
      Piva: "",
      CodFisc: "",
      Note: "",
      Pagamento: "",
    },
    tipoCliente: ["", "Privato", "Azienda"],

    attesa: false,

    textRules: [(v) => !!v || "Il campo è obbligatorio"],
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      // NOTA: quando apro si attiva la validazione
      this.$nextTick(() => this.$refs.form.resetValidation());

      // Reset
      Object.assign(this.dati, this.dati_empty);

      if (this.codCliente == 0) return;

      let res = await get_clientiOrdini(this.codCliente);

      this.dati = res.data;
    },

    async salva() {
      // Validazione
      if (this.$refs.form.validate() == false) {
        return;
      }

      this.attesa = true;

      let res = await save_clientiOrdini(this.dati);

      this.attesa = false;

      this.chiudi(true);
    },

    chiudi(aggiorna) {
      this.$emit("chiudiDialog", aggiorna);
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },
};
</script>