<template src="./statoOrdini.html"></template>

<script>
import {
  get_datiOrdini,
  stampa_ordini,
  get_docOrdini,
  download_docOrdini,
} from "@/api/api.statoordini.js";

import AutoCompleteClienti from "@/components/ui/AutoCompleteClienti";

export default {
  components: {
    AutoCompleteClienti,
  },

  data: function () {
    return {
      dati: [],
      documenti: [],

      loadingDocument: false,
      loadingPrint: false,

      headers: [
        { text: "Nr.Ordine", value: "NumOrdine" },
        { text: "Data", value: "DataOrdine" },
        { text: "RagSoc", value: "RagSoc" },
        { text: "Riferimento", value: "Riferimento" },
        { text: "Q.tà", align: "right", value: "Qta" },
        { text: "Serie", value: "CodFamiglia" },
        { text: "Colore", value: "CodColore" },
        /*  { text: "Data consegna richiesta", value: "DataConsegnaRichiesta" }, */
        { text: "Data consegna prevista", value: "DataConsegnaCliente" },
        { text: "Sett.", align: "right", value: "SettimanaConsegna" },
        { text: "Stato", align: "right" },
      ],

      search: "",
      pagination: {
        itemsPerPage: 200,
      },
      sortBy: "",
      sortDesc: false,

      attesa: false,
      attesaDownload: false,

      filtroSel: "In Corso",
      filtriRicerca: ["In Corso", "Archiviati"],
      arrClienti: [],
      codClienteSel: "",
      selectedRowDettaglio: [],
    };
  },

  methods: {
    async caricaDati() {
      // Reset
      this.dati = [];

      this.attesa = true;

      try {
        let res = await get_datiOrdini(this.getFiltro(false));

        this.dati = res.data;

        // Carico i clienti
        this.arrClienti = [];
        for (let i in this.dati) {
          this.arrClienti.push({
            CodCliente: this.dati[i].CodCliente,
            RagSoc: this.dati[i].RagSoc,
          });
        }
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    getColorePerc(item) {
      if (item.StatoPerc == 100 && item.Finito) return "green accent-4";
      else if (item.StatoPerc == 100 && item.FinitoCairo) return "cyan accent-3";
      else if (item.StatoPerc >= 60) return "light-blue accent-3";
      else if (item.StatoPerc >= 20) return "amber darken-1";
      else return "grey lighten-1";
    },

    async stampa() {
      this.loadingPrint = true;

      try {
        let res = await stampa_ordini(this.getFiltro(true));

        this.$i2kFunctions.createBlobPdf(res.data);

        this.loadingPrint = false;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.loadingPrint = false;
    },

    getFiltro(stampa) {
      let filtro = this.filtroSel + "/";

      filtro +=
        this.codClienteSel.toString().length > 0 ? this.codClienteSel : "";

      // Controllo stampa
      if (stampa) {
        filtro += "/" + this.pagination.sortBy + "@";

        filtro += this.pagination.descending == true ? "desc" : "asc";
      }

      return filtro;
    },

    async visDocumenti(item, expanded) {
      // Pulizia
      this.documenti = [];

      // Reset
      this.selectedRowDettaglio = [];

      // Controllo espansione
      if (!expanded) return;

      this.selectedRowDettaglio = item;

      // Attesa
      this.loadingDocument = true;

      try {
        let res = await get_docOrdini(item.NumOrdine);

        this.documenti = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.loadingDocument = false;
    },

    async scaricaFile(item) {
      this.attesaDownload = true;

      try {
        let res = await download_docOrdini(item);

        // Controllo pdf (apertura nel browser)
        if (this.$i2kFunctions.getExtensionFile(item.NomeDocumento) == ".pdf") {
          this.$i2kFunctions.createBlobPdf(res.data);
        } else {
          this.$functionsApp.downloadFileBlob(res.data, item.NomeDocumento);
        }
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaDownload = false;
    },

    clienteSelezionato(cliente) {
      this.codClienteSel = cliente == null ? "" : cliente;

      this.caricaDati();
    },

    changeSort(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    },
  },

  mounted() {


    this.caricaDati();
  },

  computed: {


    role() {
      return this.$store.getters["user/getRole"]
    },

    nascondiConsegnaPrevista() {
      let res = false;

      if (!!this.role == false || !!this.dati == false || Object.keys(this.dati).length == 0)
        res = true
      else if (this.role == "Cliente")
        res = !!this.dati.find((f) => f.NascondiDataConsegna == true);    // Controllo se nascondere la data consegna nel caso dei clienti

      return res;
    },
  },
};
</script>

