<template src="./cercaOrdini.html"></template>

<script>
import { get_listaOrdini } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    ordine: { type: Boolean, default: false },
  },

  data: () => ({
    headers: [],
    selectedRow: [],

    filtriRicerca: ["Utente", "Azienda"],
    filtroSel: "Utente",

    dati: [{ NumPrev: "" }],
    loadingDati: false,

    search: "",

    sortBy: "NumPrev",
    sortDesc: true,

    archivio: false
  }),

  methods: {
    async caricaDati() {
      if (this.dialog == false) return;

      // NOTA: non è impostatato in "data" per usare vuex
      this.headers = [
        {
          text: this.$store.getters["user/traduci"]("Numero"),
          value: "NumPrev",
          width: "100",
        },
        { text: this.$store.getters["user/traduci"]("Data"), value: "Data" },
        {
          text: this.$store.getters["user/traduci"]("Cliente"),
          value: "RagSoc",
        },
        {
          text: this.$store.getters["user/traduci"]("Riferimento"),
          value: "Riferimento",
          width: "200",
        },
        { text: "Destinazione", value: "DestinazioneCantiere", width: "130" },
        { text: "Utente", value: "Utente", width: "100" },
      ];

      this.dati = [];
      this.loadingDati = true;
      this.selectedRow = [];

      try {
        let res = await get_listaOrdini(this.ordine + "/" + this.filtroSel + "/" + this.archivio);

        this.dati = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.loadingDati = false;
    },

    conferma() {
      // Controllo presenza righe
      if (Object.keys(this.selectedRow).length == 0) {
        this.$toast.alert("Ordine non selezionato");
        return;
      }

      this.close(this.selectedRow.IDPrev);
    },

    close(idPrev) {
      this.$emit("chiudiDialog", idPrev);
    },

    clickRow(item) {
      this.selectedRow = item;
    },
  },

  computed: {
    getRole() {
      return this.$store.getters["user/getRole"];
    },
  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },
};
</script>
