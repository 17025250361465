<template >
  <div class="google-map" :id="mapName"></div>
</template>

<script>
import { set_VisitaClienti } from "@/api/api.clienti.js";

export default {
  props: {
    dati: { type: Array, default: () => [] },
  },

  data: function () {
    return {
      mapName: "google-map",
      map: null,
      bounds: null,
      infoWindow: null,
      markers: [],
    };
  },

  methods: {
    caricaDati() {
      // Pulizia
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];

      if (this.dati.length == 0) return;

      // Controllo mappa già caricata
      if (this.map == null) {
        this.bounds = new window.google.maps.LatLngBounds();

        this.infoWindow = new window.google.maps.InfoWindow();

        const element = document.getElementById(this.mapName);
        const mapCentre = this.dati[0];
        const options = {
          center: new window.google.maps.LatLng(
            mapCentre.Latitudine,
            mapCentre.Longitudine
          ),
          scrollwheel: true
        };

        this.map = new window.google.maps.Map(element, options);

        this.map.setOptions({ minZoom: 5, maxZoom: 15 });
      }

      // Ciclo sulle coordinate
      this.dati.forEach((item) => {
        const position = new window.google.maps.LatLng(
          item.Latitudine,
          item.Longitudine
        );

        const marker = new window.google.maps.Marker({
          position,
          map: this.map,
          title: item.RagSoc,
        });

        this.map.fitBounds(this.bounds.extend(position));

        let self = this;

        let dateobj = new Date();
        let dateStr = dateobj.toLocaleDateString("it-IT");
        let note = "";

        let contentString =
          '<div style=" width:450px;">' +
          '<h1 style="width:450px; background-color: #48b5e9; color:#FFF; padding: 10px; margin-bottom:10px;">' +
          item.RagSoc +
          " </h1>" +
          '<div style="text-align:center; margin-bottom:10px; font-size: 16px;">' +
          '<p style=""><b>Ultimo passaggio :</b> ' +
          (item.DataUltimaVisita || "Non presente.") +
          "</p>" +
          '<div style="text-align:left; margin-bottom: 10px;"><span style="width:50px; display:inline-block;">Data :</span>' +
          '<input id="txtData" type="text" value="' +
          dateStr +
          '" style=" width:110px; height:50px; border-radius:5px; border:1px solid; text-align:center;"></div> ' +
          '<div style="text-align:left;"><span style="width:50px; display:inline-block; vertical-align: top;">Note :</span>' +
          '<textarea rows="4" cols="40" id="txtNote" value="' +
          note +
          '" style="border-radius:5px; border:1px solid; padding:10px;"></textarea> </div>' +
          '<div style="width:100%; ">' +
          ' <input id="btnFatto" idCliente=' +
          item.CodCliente +
          ' style="font-size: 20px; margin:auto;  width:150px; height:50px; border-radius:5px; background-color:lightgrey; " type="button" value="Salva" ></div>' +
          "</div>" +
          "</div>";

        window.google.maps.event.addListener(
          self.infoWindow,
          "domready",
          function () {
            var button, codCli, data, note;

            button = document.getElementById("btnFatto");

            button.onclick = function () {
              codCli = parseInt(button.getAttribute("idCliente"));
              data = document.getElementById("txtData").value;
              note = document.getElementById("txtNote").value;

              self.salva(codCli, data, note);
            };
          }
        );

        // Fumetto
        window.google.maps.event.addListener(marker, "click", function () {
          self.infoWindow.setContent(contentString);

          self.infoWindow.open(self.map, this);
        });

        // Inserisco il marker
        this.markers.push(marker);
      });
    },

    async salva(codCliente, data, note) {
      // Verifico la data
      if (!this.$i2kFunctions.ValidateDate(data)) {
        this.$toast.alert("Data non valida");
        return false;
      }

      // Chiudo il fumetto
      this.infoWindow.close();

      let dataArr = data.split("/");
      let dataIso = dataArr[2] + "-" + dataArr[1] + "-" + dataArr[0];

      try {
        let d = { CodCliente: codCliente, DataVisita: dataIso, Note: note };
        await set_VisitaClienti(d);

        this.$emit("aggiornaDati");
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dati(newValore, oldValore) {
      this.caricaDati();
    },
  },
};
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 1000px;
  margin: 0 auto;
  background: gray;
  color: black;
}
</style>