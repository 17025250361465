<template src="./clienti.html"></template>

<script>
import { get_Clienti, get_stampaClienti } from "@/api/api.clienti.js";
import AutoCompleteClienti from "@/components/ui/AutoCompleteClienti";
import Clienti from "@/components/ui/clienti/ListaClienti";
import Mappa from "@/components/ui/clienti/Map";

export default {
  components: {
    AutoCompleteClienti,
    Clienti,
    Mappa,
  },

  data: function () {
    return {
      dati: [],
      filtroDati: [],

      filtroProv: [],
      filtroProvSel: "",
      filtroRegione: [],
      filtroRegioneSel: "",
      filtroCodCliente: "",
      filtroRadioGroup: "Tutti",
      filtroLocalitaSel: "",
      arrClienti: [],
      totRecord: 0,
      filtroTipoAnag: ["", "Cliente", "Fornitore", "Contatto"],
      filtroTipoAnagSel: "",

      loadingPrint: false,
    };
  },

  methods: {
    async caricaDati() {
      // Reset
      this.dati = [];

      try {
        let par =
          "?prov=" + this.filtroProvSel + "&regione=" + this.filtroRegioneSel + "&tipoAnag=" + this.filtroTipoAnagSel;

        let resp = await get_Clienti(par);

        this.dati = resp.data;

        // Filtri di ricerca
        this.caricaFiltri();

        this.getDati();

        //this.caricaMappa();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    caricaFiltri() {
      // Controllo filtri gia caricati
      if (this.filtroProv.length > 0) return;

      this.filtroProv.push("");
      this.filtroRegione.push("");

      for (let i = 0; i < this.dati.length; i++) {
        if (!!this.filtroProv.find((f) => f == this.dati[i].Provincia) == false)
          this.filtroProv.push(this.dati[i].Provincia);

        if (
          !!this.filtroRegione.find((f) => f == this.dati[i].Regione) == false
        )
          this.filtroRegione.push(this.dati[i].Regione);
      }
    },

    getDati() {
      this.arrClienti = [];

      this.filtroDati = this.dati;

      // Prov
      if (this.filtroProvSel)
        this.filtroDati = this.filtroDati.filter(
          (f) => f.Provincia == this.filtroProvSel
        );

      // Regione
      if (this.filtroRegioneSel)
        this.filtroDati = this.filtroDati.filter(
          (f) => f.Regione == this.filtroRegioneSel
        );

      // Localita
      if (this.filtroLocalitaSel) {
        this.filtroDati = this.filtroDati.filter(
          (f) =>
            f.Localita.toString().toLowerCase() ==
            this.filtroLocalitaSel.toLowerCase()
        );
      }

      // Clienti
      if (this.filtroCodCliente) {
        this.filtroDati = this.filtroDati.filter(
          (f) => f.CodCliente == this.filtroCodCliente
        );
      }

      // Da visitare
      if (this.filtroRadioGroup == "DaVisitare")
        this.filtroDati = this.filtroDati.filter((f) => f.Visita == true);
      else if (this.filtroRadioGroup == "Visitati")
        this.filtroDati = this.filtroDati.filter(
          (f) => f.DataUltimaVisita != null
        );

      // Carico i clienti filtrati
      for (let i in this.filtroDati) {
        this.arrClienti.push({
          CodCliente: this.filtroDati[i].CodCliente,
          RagSoc: this.filtroDati[i].RagSoc,
        });
      }

      this.totRecord = Object.keys(this.filtroDati).length;
    },

    clienteSelezionato(codCliente) {
      this.filtroCodCliente = codCliente;

      this.getDati();
    },

    async stampa() {
      this.loadingPrint = true;

      try {
        let res = await get_stampaClienti(this.filtroDati);

        this.$i2kFunctions.createBlobPdf(res.data);
      } catch (err) {
        console.log(this.$i2kFunctions.getErrorMsg(err));
      }

      this.loadingPrint = false;
    },

    resetLocalita() {
      this.filtroLocalitaSel = "";
      this.getDati();
    },
  },

  mounted() {
    this.caricaDati();
  },
};
</script>
