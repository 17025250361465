<template>
  <v-dialog v-model="dialog" persistent max-width="450" @keydown.esc="chiudi(false)">
    <v-card height="250" :loading="attesa">
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark v-on:click="chiudi()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Impostazioni</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn text v-on:click="salva()" :disabled="attesa" :loading="attesa">Ok
            <v-icon right>save</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card class="mx-2 mt-2 elevation-0" height="180" style="border-radius: 5px; background-color: lightgrey">
        <v-card-title class="pa-1 body-2 font-weight-medium">
          Opzioni Stampa
        </v-card-title>

        <v-container>
          <v-row row wrap>
            <v-col cols="4" class="pa-0 d-flex flex-column justify-center align-center">
              <v-checkbox label="Senza Misure" v-model="dati.StampaNoMisure"></v-checkbox>
            </v-col>

            <v-col cols="4" class="pa-0 d-flex flex-column justify-center align-center">
              <v-checkbox label="Senza Prezzi" v-model="dati.StampaNoPrezzi">
              </v-checkbox>
            </v-col>

            <v-col cols="4" class="pa-0 d-flex flex-column justify-center align-center">
              <v-checkbox label="Senza Importo detraibile" v-model="dati.StampaNoDetraibile">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row row wrap>
            <v-col cols="4" class="pa-0 ml-2">
              <v-select style="width:80px;" label="Lingua" :items="lingua" v-model="dati.LinguaStampa"></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_impostazioni, set_impostazioni } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    attesa: false,

    dati: {},

    lingua: ["", "IT", "FR"]
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      this.dati = {};
      this.attesa = true;

      let res = await get_impostazioni();

      if (res.data) this.dati = res.data;

      this.attesa = false;
    },

    async salva() {
      let res = await set_impostazioni(this.dati);

      this.chiudi();
    },

    chiudi() {
      this.$emit("chiudiDialog");
    },
  },

  watch: {
    dialog() {
      this.caricaDati();
    },
  },
};
</script>
