<template src="./generaOrdine.html"></template>

<script>
import {
  get_generaConfermaOrdine,
  get_annullaConfermaOrdine,
  set_noteConfermaOrdine,
  get_allegatiOrdine,
  del_allegatiOrdine,
  get_testataOrdine,
  get_articoliObsoletiConfermaOrdine
} from "@/api/api.ordini.js";

import DialogAllegati from "@/components/dialog/ordini/Allegati";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    datiDettaglio: { type: Array, default: () => [] },
  },

  components: {
    DialogAllegati,
  },

  data: () => ({
    stepper: ["Inizio", "Note", "Allegati", "Fine"],
    step: 1,
    stepClickEnabled: false,
    stepColor: "",
    stepMax: 4,

    modificaNote: false,

    datiTestata: {},

    headersAllegati: [
      { text: "Pos.", value: "Prog", width: "50", sortable: false },
      { text: "Descrizione", value: "Descrizione", sortable: false },
      { text: "Documento", value: "Documento", sortable: false },
    ],
    datiAllegati: [],
    dialogAllegati: false,
    progAllegati: 0,

    ordineInviato: false,
    ordineGenerato: false,
    articoliObsoleti: [],
    articoliImportoZero: [],

    attesa: false,
  }),

  methods: {
    async caricaDati() {
      if (this.dialog == false) return;

      this.attesa = true

      // Reset
      this.datiTestata = {};
      this.datiAllegati = [];
      this.step = 1;
      this.progAllegati = 0;
      this.modificNote = false;
      this.articoliObsoleti = []
      this.articoliImportoZero = []

      // Note
      await this.caricaTestata();

      // Allegati
      await this.caricaAllegati();

      // Articoli obsoleti
      let resArtObs = await get_articoliObsoletiConfermaOrdine(this.idPrev)
      this.articoliObsoleti = resArtObs.data

      // Articoli prezzo zero
      this.articoliImportoZero = this.datiDettaglio.filter(f => f.CodSerie != 'LIBERA' && f.Importo == 0)

      this.attesa = false

    },

    async inviaOrdine() {
      let confirm = await this.$confirm.open(
        "Genera",
        "Vuoi generare l'ordine ?"
      );

      if (!confirm) return;

      this.attesa = true

      try {
        // Salvo le note
        this.salvaNote();

        let res = await get_generaConfermaOrdine(this.idPrev);


      } catch (err) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(err));
      }

      this.attesa = false

      this.chiudi(true);

      this.$toastFixed.info("Ordine inviato correttamente, a breve sarà visibile su 'Stato Ordini'");
    },

    async annullaInvio() {
      let confirm = await this.$confirm.open(
        "Annulla",
        "Vuoi annullare l'invio ?"
      );

      if (!confirm) return;

      let res = await get_annullaConfermaOrdine(this.idPrev);

      if (res.data != "OK") {
        this.$toast.alert(res.data);
        return;
      }

      this.chiudi(true);
    },

    chiudi(aggiorna) {
      this.$emit("chiudiDialog", aggiorna);

    },

    /* ---------- Note ----------- */
    async caricaTestata() {
      let res = await get_testataOrdine(this.idPrev);

      this.datiTestata = res.data;

      this.ordineInviato = this.datiTestata.OrdineInviato;
      this.ordineGenerato = this.datiTestata.OrdineGenerato;

      // Quando invio a ordine (la prima volta)  carico le note\destinazione 
      if (this.ordineGenerato == false) {
        if (this.datiTestata.Note && this.datiTestata.NoteOrdine.length == 0)
          this.datiTestata.NoteOrdine = this.datiTestata.Note
      }

    },

    async salvaNote() {

      let res = await set_noteConfermaOrdine(this.datiTestata);

      this.modificaNote = false;
    },
    /* ------------------------------- */

    /* ---------- Allegati ----------- */
    async caricaAllegati() {
      // Reset
      this.progAllegati = 0;

      let res = await get_allegatiOrdine(this.idPrev);

      this.datiAllegati = res.data;
    },

    nuovoAllegato() {
      this.progAllegati = 0;

      // Apro la dialog
      this.dialogAllegati = true;
    },

    modificaAllegato() {
      if (this.progAllegati == 0) return;

      // Apro la dialog
      this.dialogAllegati = true;
    },

    eliminaAllegato() {
      if (this.progAllegati == 0) return;
      this.eliminaRigoAllegati();
    },

    async eliminaRigoAllegati() {
      let resConf = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare il rigo selezionato ?"
      );

      if (!resConf) return;

      let res = await del_allegatiOrdine(this.idPrev + "/" + this.progAllegati);

      // Ricarico i dati
      this.caricaAllegati();
    },

    rowClickAllegati(item, row) {
      this.progAllegati = item.Prog;
    },

    itemRowAllegatiBackground(item) {
      return this.progAllegati == item.Prog ? "grey" : "white";
    },

    closeDialogAllegati(aggiorna) {
      this.dialogAllegati = false;

      this.caricaAllegati();
    },
    /* ------------------------------- */

    prevStep() {
      this.step--;

    },

    nextStep() {
      if (this.step == 1) {
        // ----------------
        // Avvio
        // ----------------
      } else if (this.step == 2) {
        // ----------------
        // Note
        // ----------------

        if (this.modificaNote)
          this.salvaNote();

      } else if (this.step == 3) {
        // ----------------
        // Allegati
        // ----------------
      } else if (this.step == this.stepMax) {
        // ----------------
        // Fine
        // ----------------

        this.inviaOrdine();
        return;
      }

      this.step++;
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },

  computed: {
    getAllegati() {
      return this.datiAllegati.filter((f) => f.Ordine == true);
    },

    getRole() {
      return this.$store.getters["user/getRole"];
    },

    checkDatiImportati() {
      return this.ordineGenerato == false && Object.keys(this.datiTestata).length > 0 && this.datiTestata.Note.length > 0
    },

    checkButtonAvanti() {
      // NOTA: su uitimo step e ordine già inviato non visualizzo il bottone
      //       lo faccio su ulitmo step per dare la possibilità di vedere note e allegati inseriti
      if (this.step == this.stepMax && this.ordineInviato)
        return false

      // NOTA: se ci sono articoli obsoleti blocco subito
      if (this.articoliObsoleti.length > 0)
        return false
      if (this.articoliImportoZero.length > 0)
        return false

      return true
    }
  },
};
</script>
