<template src="./costo.html"></template>

<script>
import { get_testataCosti } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
  },

  data: () => ({
    dati: { CostoTotale: 0, Ricarico: 0 },
  }),

  methods: {
    async caricaDati() {
      if (this.dialog == false) return;

      // Reset
      this.dati.CostoTotale = 0;
      this.dati.Ricarico = 0;

      try {
        let res = await get_testataCosti(this.idPrev);

        if (res.data) this.dati = res.data;
      } catch (error) {
        console.log(error);
      }
    },

    chiudi() {
      this.$emit("chiudiDialog");
    },
  },
  mounted() {
    this.caricaDati();
  },
  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },
};
</script>
