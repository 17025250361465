<template>
  <v-app-bar :color="verificaTest() ? '#C41E3A' : '#06163A'" dark dense app fixed
    :clipped-left="$vuetify.breakpoint.mdAndUp">
    <v-app-bar-nav-icon @click.stop="leftBar()"></v-app-bar-nav-icon>

    <v-app-bar-title class="label">
      <span class="font-weight-light mr-5" style="font-size: 20px">{{ (verificaTest() ? '# TEST #' : 'Area riservata')
      }}</span>

      <span class="font-weight-light ml-5" style="font-size: 16px">
        <v-icon color="grey">phone</v-icon>+ (39) 019 590410
      </span>

      <span class="font-weight-light ml-7" style="font-size: 16px">
        <v-icon color="grey" class="mr-2">mail</v-icon><a href="mailto: bergero@bergeroinfissi.it"
          style="text-decoration: none !important; color: inherit !important;">bergero@bergeroinfissi.it</a>
      </span>
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <!-- Attesa -->
    <v-progress-circular :size="40" width="2" color="white" indeterminate v-if="loading"></v-progress-circular>

    <!-- Nome pagina -->
    <v-chip color="primary" text-color="white" style="font-size: 16px" class="font-weight-regular"
      v-if="loading == false">
      <v-icon left class="pa-2">{{ pageCurrent.split("|")[1] }}</v-icon>
      {{ pageCurrent.split("|")[0] }}
    </v-chip>

    <v-spacer></v-spacer>
    <v-spacer></v-spacer>

    <div class="label">
      <!-- Bandiere lingua -->
      <v-btn text outlined @click="$store.commit('user/SET_LINGUA', 'IT')" min-width="40" height="30"
        style="border: 1px solid grey" :style="{ border: lang == 'IT' ? '1px solid grey' : '0px' }">
        <img src="~@/assets/img/flag_ita.png" />
      </v-btn>

      <v-btn class="mx-2" text outlined @click="$store.commit('user/SET_LINGUA', 'FR')" min-width="40" height="30"
        :style="{ border: lang == 'FR' ? '1px solid grey' : '0px' }">
        <img src="~@/assets/img/flag_fra.png" />
      </v-btn>
    </div>

    <!-- Utente -->
    <v-app-bar-title class="white--text font-weight-regular" style="font-size: 16px">
      {{ utente }}
    </v-app-bar-title>

    <!-- Menu utente -->
    <v-menu bottom offset-y :close-on-click="true" :close-on-content-click="true" :nudge-width="200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon large>account_circle</v-icon>
        </v-btn>
      </template>

      <v-card light>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon x-large>account_circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Utente:</v-list-item-title>
              <v-list-item-subtitle>{{ utente }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="disabilitaBtn" v-on:click="esci()">Esci</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>



<script>
import { mapState } from "vuex";

export default {
  props: {
    utente: { type: String, default: "" },
    pageCurrent: { type: String, default: "" },
    loading: { type: Boolean, default: false },
  },

  data: () => ({
    disabilitaBtn: false,
  }),

  methods: {
    esci() {
      this.disabilitaBtn = true;

      this.$emit("esci");
    },

    leftBar() {
      this.$emit("showLeftBar");
    },

    verificaTest() {
      return location.port == 82
    }
  },

  computed: {
    ...mapState({
      lang: (state) => state.user.lingua,
    }),
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .label {
    display: none;
  }
}
</style>