<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    @keydown.esc="chiudi()"
    width="500"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark v-on:click="chiudi()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Abbina ordine InfoAzienda</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text v-on:click="salva()" :disabled="attesa" :loading="attesa"
            >Abbina
            <v-icon right>launch</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                label="Ordine"
                outlined
                v-model="numOrdine"
                full-width
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pb-0">
              Ordini abbinati:
              <ul v-for="(item, i) in dati" :key="i">
                <li style="font-size: 12px">
                  <span
                    v-html="
                      item.NumOrdine +
                      '<br>' +
                      item.RagSoc +
                      '  - Rif.' +
                      item.Riferimento
                    "
                  ></span>
                  <v-btn icon small @click="eliminaAbbinamento(item.NumOrdine)"
                    ><v-icon>delete</v-icon></v-btn
                  >
                </li>
              </ul>
              <v-divider></v-divider>
            </v-col>

            <v-col cols="6"
              ><v-switch
                label="Conferma ordine"
                v-model="stampaConferma"
              ></v-switch
            ></v-col>

            <v-col cols="6" class="mt-4"
              ><v-btn @click="stampa" :loading="attesaStampa"
                >Genera PDF</v-btn
              ></v-col
            >
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  get_abbinamentiInfoAzienda,
  get_abbinaConfermaInfoAzienda,
  get_stampaConfermaInfoAzienda,
  del_abbinamentiInfoAzienda,
} from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
  },

  data: () => ({
    attesa: false,
    attesaStampa: false,
    numOrdine: "",

    stampaConferma: false,

    aggiornaOrdine: false,

    dati: [],

    aggiornaDati: false,
  }),

  methods: {
    async caricaDati() {
      // Reset
      this.numOrdine = "";
      this.aggiornaOrdine = false;
      this.dati = [];

      if (this.dialog == false) return;

      let res = await get_abbinamentiInfoAzienda(this.idPrev);
      this.dati = res.data;
    },

    async salva() {
      if (!!this.numOrdine == false) return;

      let confirm = await this.$confirm.open(
        "Abbina",
        "Confermi l'abbinamento ?"
      );
      if (!confirm) return;

      this.attesa = true;

      try {
        let res = await get_abbinaConfermaInfoAzienda(
          this.idPrev + "/" + this.numOrdine
        );

        // Controllo errori
        if (res.data != "OK") this.$toast.alert(res.data);

        // Ricarico i dati
        this.ricaricaDati();
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    async stampa() {
      if (this.dati.length == 0) return;

      if (this.stampaConferma == false) return;

      let confirm = await this.$confirm.open(
        "Conferma ordine",
        "Vuoi generare la conferma ordine ?"
      );

      if (!confirm) return;

      this.attesaStampa = true;

      try {
        let res = await get_stampaConfermaInfoAzienda(
          this.idPrev + "/classicaPrezzi"
        );

        window.open(res.data, "_blank");
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaStampa = false;

      this.aggiornaOrdine = true;
    },

    async eliminaAbbinamento(numOrd) {
      let confirm = await this.$confirm.open(
        "Elimina abbinamento",
        "Confermi eliminazione abbinamento?"
      );
      if (!confirm) return;

      try {
        let res = await del_abbinamentiInfoAzienda(this.idPrev + "/" + numOrd);

        // Controllo errori
        if (res.data != "OK") this.$toast.alert(res.data);
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.ricaricaDati();
    },

    async ricaricaDati() {
      this.aggiornaDati = true;

      let nrSwap = this.numOrdine;

      await this.caricaDati();

      // Ripropongo il numero precedente
      this.numOrdine = nrSwap;
    },

    chiudi() {
      this.$emit("chiudiDialog", this.aggiornaDati);

      this.aggiornaDati = false;
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog() {
      this.caricaDati();
    },
  },
};
</script>