import {
  login_user,
  logout_user,
} from "@/api/api.auth.js";
import qs from 'qs'
import storage from "@/services/storage.js"

const auth = {
  namespaced: true,

  state: {
    token: storage.getToken(),
    refToken: storage.getRefToken(),
    dataExpired: storage.getDataExpiredToken(),
    remember: !!storage.getRefToken(),
  },

  mutations: {

    SET_TOKEN: (state, payload) => {

      state.token = payload.resp.access_token

      state.remember = payload.ricorda

      // Controllo se memorizzare il refresh token
      if (payload.ricorda)
        state.refToken = payload.resp.refresh_token

      state.dataExpired = storage.getExpiration(payload.resp.expires_in)

      // Aggiorno lo storage
      storage.setStorage(state)

    },


  },

  actions: {


    // ------ LOGIN -------
    login({
      commit,
      dispatch
    }, user) {


      return new Promise((resolve, reject) => {

        login_user(qs.stringify({
          grant_type: 'password',
          username: user.username,
          password: user.password
        })).then(resp => {

          commit("SET_TOKEN", {
            resp: resp.data,
            ricorda: true
          })

          resolve(resp)

        }).catch(err => {

          reject(err)
        })
      })
    },

    // ------ LOGOUT -------
    logout({
      commit,
      state
    }) {

      return new Promise((resolve, reject) => {

        logout_user().then(resp => {

          // Ritorno al login
          storage.logout()

          resolve(resp)

        }).catch(err => {

          console.log(err)

          // NOTA: in caso di errore esco comunque
          storage.logout()

          reject(err)
        })
      })



    },

    // ------ REFRESH TOKEN -------
    refreshToken({
      commit,
      dispatch
    }, user) {

      return new Promise((resolve, reject) => {

        login_user(qs.stringify({
          grant_type: 'refresh_token',
          refresh_token: storage.getRefToken()
        })).then(resp => {

          commit("SET_TOKEN", {
            resp: resp.data,
            ricorda: true
          })

          resolve(resp)

        }).catch(err => {

          reject(err)
        })
      })
    },



  },

  getters: {
    isAuthenticated: state => !!state.token,
  }


}


export default auth
