<template src="./login.html"></template>

<script>
export default {
  data: function () {
    return {
      e1: true,

      cred: {
        username: "",
        password: "",
      },
      disabilitaBtn: false,
    };
  },

  methods: {
    async submit() {
      window.scrollTo(0, 0); // Scroll to top (serve sul mobile per vedere eventuali messaggi)

      // Validazione
      if (this.$refs.formLogin.validate() == false) {
        return;
      }

      // Disabilito bottone login
      this.disabilitaBtn = true;

      // Reset messaggi
      this.$toast.close();

      try {
        // Login
        await this.$store.dispatch("auth/login", this.cred);

        // Vado alla pagina riservata
        this.$router.push({ path: "/" });
      } catch (err) {
        // Abilito bottone login
        this.disabilitaBtn = false;

        // Messaggio
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(err));
      }

      return false;
    },
  },
};
</script>

