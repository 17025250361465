import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import VTooltip from 'v-tooltip'

import FunctionsApp from "@/utils/functions.js";
import ToastFixed from '@/plugins/toastFixed/toast';

import $i2kFunctions from "i2kjslibrary";
import Toast from 'i2kjslibrary/plugins/vuetify/toast/toast';
import Confirm from 'i2kjslibrary/plugins/vuetify/confirm/confirm';
import Wait from 'i2kjslibrary/plugins/vuetify/wait/wait';

import Load from "./LOAD.js"

import './assets/css/style.css'

// Vuetify
Vue.use(Vuetify)

// i2k Plugins
Vue.use(Toast, { version: 2 })
Vue.use(Confirm, { version: 2, titleBackground: 'primary' })
Vue.use(Wait, { version: 3 })

// App Plugins
Vue.use(ToastFixed, { version: 2 })

// Tooltip
Vue.use(VTooltip)

Vue.prototype.$functionsApp = FunctionsApp;
Vue.prototype.$i2kFunctions = $i2kFunctions;
Vue.prototype.$load = Load;

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: 'md',
    },
    theme: {
      themes: {
        light: {
          primary: '#14589C'
        },
        dark: {
          primary: '#14589C',
        },
      },

    }
  })
}).$mount('#app')
