<template src="./datiTestata.html"></template>

<script>
import {
  get_testataOrdine,
  save_testataOrdine,
  get_testataComuni,
  get_testataTrasmitLimiti,
  get_testataUtenti
} from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    bloccato: { type: Boolean, default: false },
  },

  data: () => ({
    tipoLavoro: ["Ristrutturazione", "Nuova costruzione"],

    dati: {
      IdPrev: 0,
      Data: "",
      Riferimento: "",
      DestinazioneCantiere: "",
      TipoLavoro: "",
      ComuneCantiere: "",
      ZonaClimatica: "",
      Uw: 0,
      Note: "",
    },

    trasmit: {},

    comuneSel: {},
    comuni: [],

    utenteSel: "",
    utenti: [],

    menu: false,
    attesa: false,
  }),

  methods: {
    async caricaDati() {
      // Reset
      this.dati = {};
      this.dati.Data = "";
      this.comuni = [];
      this.trasmit = {};
      this.comuneSel = {};
      this.utenteSel = ""

      if (this.dialog == false) return;

      this.attesa = true;

      try {
        let resComuni = await get_testataComuni();
        this.comuni = resComuni.data;

        let resTrasmit = await get_testataTrasmitLimiti();
        this.trasmit = resTrasmit.data;

        let resUtenti = await get_testataUtenti();
        this.utenti = resUtenti.data;

        let res = await get_testataOrdine(this.idPrev);
        this.dati = res.data;


        // Impostazioni dati
        if (this.dati) {
          // Comune
          if (this.dati.ComuneCantiere) {
            this.comuneSel = this.comuni.find(
              (f) => f.Comune == this.dati.ComuneCantiere
            );

            this.setDatiComune();
          }


          // Utente
          if (this.dati.Utente) {
            this.utenteSel = this.dati.Utente
          }
        }




      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    async salva() {
      this.dati.IdPrev = this.idPrev;

      try {

        let res = await save_testataOrdine(this.dati);
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.chiudi(true);
    },

    setDatiComune() {
      this.dati.ComuneCantiere = this.comuneSel.Comune;
      this.dati.ZonaClimatica = this.comuneSel.ZonaClimatica;

      this.setTrasmittanza();
    },

    setTrasmittanza() {
      let res = this.trasmit.find(
        (f) =>
          f.ZonaClimatica == this.dati.ZonaClimatica &&
          f.Tipologia == this.dati.TipoLavoro
      );
      if (res) this.dati.Uw = res.Uw;
    },

    setDatiUtente() {
      this.dati.Utente = this.utenteSel;
    },

    chiudi(salva) {
      this.$emit("chiudiDialog", salva);
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    dataPrev() {
      if (this.dati.Data.length == 0) return "";

      const [year, month, day] = this.dati.Data.split("-");
      return `${day}/${month}/${year}`;
    },

    getUtenteInterno() {
      return this.$store.getters["user/getRole"] == "Interno";
    },

  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },


};
</script>
