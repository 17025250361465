<template src="./profilo.html"></template>

<script>
import { get_datiprofiloUtente, save_mailUser } from "@/api/api.utente.js";
import { del_VisitaClienti } from "@/api/api.clienti.js";

import { mapState } from "vuex";

export default {
  props: {
    codCliente: { type: Number, default: 0 },
  },
  data() {
    return {
      dati: [],

      dialog: false,
      editedIndex: -1,
      editedItem: {
        Mail: "",
      },
    };
  },

  methods: {
    async caricaDati() {
      // Reset
      this.dati = [];

      let param = this.codCliente || "";

      try {
        let res = await get_datiprofiloUtente(param);

        this.dati = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    editItem() {
      this.editedItem = Object.assign({}, this.dati);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      this.dati.Mail = this.editedItem.Mail;

      try {
        // Salvo su db
        let res = await save_mailUser(this.editedItem);

        this.caricaDati();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.close();
    },

    openDialog() {
      this.dialog = true;
    },

    checkAgente() {
      if (this.codCliente == 0) return false;

      return this.role == "Agente" || this.role == "AgenteInterno";
    },

    async eliminaVisita(id) {
      let confirm = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare il dato ?"
      );
      if (!confirm) return;

      try {
        await del_VisitaClienti(id);

        this.caricaDati();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.caricaDati();
  },

  // NOTA: avrei potuto usare il getters nella mounted, ma se facevo il refresh della pagina
  //      l'ajax dentro main.vue poteva finire dopo,  e quindi avevo role=""
  computed: mapState({
    role: (state) => state.user.role,
  }),
};
</script>




