<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    @keydown.esc="chiudi()"
    width="600"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark v-on:click="chiudi()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Testi</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text v-on:click="salva()" :disabled="attesa" :loading="attesa"
            >Salva
            <v-icon right>save</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-row class="pl-1">
          Iniziale
          <v-col cols="12"
            ><v-textarea
              outlined
              style="max-width: 580px"
              no-resize
              v-model="testoInizio"
            ></v-textarea
          ></v-col>

          <v-divider></v-divider>

          Finale
          <v-col cols="12"
            ><v-textarea
              outlined
              style="max-width: 580px"
              no-resize
              v-model="testoFine"
            ></v-textarea
          ></v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  get_testiArchivioOrdine,
  save_testiArchivioOrdine,
} from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
  },

  data: () => ({
    dati: [],

    attesa: false,
  }),

  methods: {
    async caricaDati() {
      if (this.dialog == false) return;

      // Reset
      this.dati = [];

      this.attesa = true;

      let res = await get_testiArchivioOrdine();

      this.dati = res.data;

      this.attesa = false;
    },

    async salva() {
      this.attesa = true;

      try {
        let res = await save_testiArchivioOrdine(this.dati);
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
        console.log(error);
      }

      this.attesa = false;

      this.chiudi();
    },

    chiudi() {
      this.$emit("chiudiDialog");
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog(val) {
      this.caricaDati();
    },
  },

  computed: {
    testoInizio: {
      get() {
        let res = this.dati.find((f) => f.Tipo == "Iniziale");
        return res ? res.Descrizione : "";
      },
      set(val) {
        let res = this.dati.find((f) => f.Tipo == "Iniziale");

        if (res) res.Descrizione = val;
        else this.dati.push({ Tipo: "Iniziale", Descrizione: val });
      },
    },

    testoFine: {
      get() {
        let res = this.dati.find((f) => f.Tipo == "Finale");
        return res ? res.Descrizione : "";
      },
      set(val) {
        let res = this.dati.find((f) => f.Tipo == "Finale");

        if (res) res.Descrizione = val;
        else this.dati.push({ Tipo: "Finale", Descrizione: val });
      },
    },
  },
};
</script>