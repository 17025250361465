<template src="./main.html"></template>


<script>
import { mapState } from "vuex";

import LeftBar from "@/components/layout/LeftBar";
import ToolBar from "@/components/layout/ToolBar";

export default {
  components: {
    LeftBar,
    ToolBar,
  },

  data: () => ({
    menu: [],
    disabilitaBtn: false,

    pollingUpdate: null,
  }),

  methods: {
    async init() {
      try {
        // Menu utente
        let res = await this.$store.dispatch("user/userInfo");
        this.menu = res.data.Menu;
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async esci() {
      this.disabilitaBtn = true;

      await this.$store.dispatch("auth/logout");
    },

    showLeftBar() {
      this.$refs.leftBar.leftBarVis = !this.$refs.leftBar.leftBarVis;
    },
  },

  mounted() {
    this.init();
  },

  computed: {
    ...mapState({
      loading: (state) => state.app.loading,
      message: (state) => state.app.message,
      utente: (state) => state.user.user,
    }),
    pageCurrent() {
      let p = this.$route.meta.pathLink || this.$route.path;
      return this.$store.getters["user/getTitolo"](p);
    },
  },

  watch: {
    message() {
      this.$toast.alert(this.message);
    },
  },
};
</script>

<style>
/* Datatable: nascondo rows-per-page */
.v-datatable__actions__select {
  visibility: hidden !important;
}
</style>


