<template>
  <v-autocomplete
    :items="select"
    label="Clienti"
    item-text="RagSoc"
    item-value="CodCliente"
    v-model="cliente"
    v-on:input="clienteSel"
    clearable
  ></v-autocomplete>
</template>


<script>
export default {
  props: {
    origine: { type: String, default: "", required: true },
    dati: { type: Array, default: () => [] }
  },

  data: () => ({
    select: [],
    cliente: []
  }),

  methods: {
    caricaDati() {
      this.select = this.dati;
    },
    clienteSel() {
      this.$emit("clienteSelezionato", this.cliente);
    }
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dati() {
      this.caricaDati();
    }
  }
};
</script>
