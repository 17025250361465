<template src="./download.html"></template>

<script>
import { get_datiDownload, get_datiDownloadFile } from "@/api/api.download.js";

export default {
  data() {
    return {
      dati: [],
      defaultTab: null,

      attesaDownload: false,
    };
  },

  methods: {
    async caricaDati() {
      try {
        let res = await get_datiDownload();

        this.dati = res.data;

        // Controllo x selezionare la prima tab
        if (this.dati[0]) this.defaultTab = "tab-" + this.dati[0].Descrizione;
      } catch (error) {
        console.log(error);
      }
    },

    async scaricaFile(src, url) {
      this.attesaDownload = true;
      try {
        let res = await get_datiDownloadFile(src);

        // Controllo pdf (apertura nel browser)
        if (this.$i2kFunctions.getExtensionFile(src) == ".pdf") {
          this.$i2kFunctions.createBlobPdf(res.data);
        } else {
          this.$functionsApp.downloadFileBlob(
            res.data,
            this.$functionsApp.getFileName(src)
          );
        }
      } catch (error) {
        console.log(error);
      }

      this.attesaDownload = false;
    },
  },

  mounted() {
    this.caricaDati();
  },
};
</script>

