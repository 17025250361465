// ---- Debug ----
const url_debug = 'http://localhost:49988';    // Pc paolo
//const url_debug = 'http://192.168.1.6'        // Intranet
//const url_debug = 'http://192.168.1.6:82' // Intranet test
//const url_debug = 'http://95.140.141.98:82'    // Internet test  





export default {

  getUrl() {
    if (window.location.host.includes("localhost"))
      return url_debug

    return window.location.protocol + "//" + window.location.host
  },

  getUrl_API() {

    let url = this.getUrl();

    if (url.includes("localhost"))
      url += "/api"
    else
      url += "/api/api"

    return url
  },

  getUrl_Img() {

    let url = this.getUrl();

    url += "/webdata/img"

    return url
  },


  getUrl_Update() {

    let url = this.getUrl();

    if (window.location.host.includes("localhost"))
      url = ""
    else
      url += "/index.html"

    return url
  }

}