import store from '../store';

export default {
    openPdfOnWeb(data) {
        let blob = new Blob([data], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
    },

    downloadFileBlob(data, fileName) {
        let blob = new Blob([data]);
        let url = window.URL.createObjectURL(blob);

        this.downloadFile(url, fileName)
    },

    downloadFile(url, fileName) {
        var element = document.createElement('a');
        element.href = url;

        element.setAttribute("download", fileName);

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    },



    getFileName(path) {
        return path.replace(/^.*[\\\/]/, "");
    },

    formatCurrency(num) {

        if (isNaN(num))
            return 0

        const formatter = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        })

        return formatter.format(num)

    },



}