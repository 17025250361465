
import request from '@/services/interceptor.js'


// -----------------------------
// Stato Ordini
// -----------------------------
export const get_datiOrdini = (par) => {
    return request({
        url: '/statoordini/' + par,
        method: 'get',
        loadingBar: true
    })
};
export const stampa_ordini = (par) => {
    return request({
        url: '/statoordini/stampa/' + par,
        method: 'get',
        responseType: 'blob',
        loadingBar: false
    })
};
export const get_docOrdini = (par) => {
    return request({
        url: '/statoordini/documenti/' + par,
        method: 'get',
        loadingBar: false
    })
};
export const download_docOrdini = par => {
    return request({
        url: '/statoordini/documenti/download',
        method: 'post',
        data: par,
        responseType: 'blob',
        loadingBar: false
    })
};

