<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>

    <!-- Plugin -->
    <toast></toast>
    <confirm></confirm>
    <wait></wait>
    <toastFixed></toastFixed>
  </v-app>
</template>

<script>
export default {
  name: "App",

  methods: {
    async init() {
      try {
        let resUpdate = await this.$i2kFunctions.checkUpdate(
          this.$load.getUrl_Update()
        );
        if (resUpdate) this.$toast.update("E' disponibile una nuova versione");
        // ---------
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
