import axios from 'axios'
import load from "../LOAD";
import store from "../store/index.js"
import storage from "./storage.js"

const instance = axios.create({
  baseURL: load.getUrl_API(),

})


// ----------------------------------
// ------------ REQUEST -------------
// ----------------------------------
instance.interceptors.request.use(config => {

  // Controllo se avviare l'attesa
  if (config.loadingBar)
    store.commit('app/setLoading', true)

  // Imposto il token
  if (store.getters['auth/isAuthenticated'])
    config.headers.Authorization = storage.getAuthHeader()

  return config

}, function (error) {

  store.commit('app/setLoading', false)

  //Request error
  console.log("request interceptor " + error)

  return Promise.reject(error)
})


// ----------------------------------
// ------------ RESPONSE ------------
// ----------------------------------
instance.interceptors.response.use((response) => {

  // Controllo fine attesa
  if (response.config.loadingBar)
    store.commit('app/setLoading', false)

  return response

}, function (error) {


  let originalRequest = error.config

  // Controllo scadenza token
  if (error.response && error.response.status === 401 && !originalRequest._retry) {

    originalRequest._retry = true

    // Login con refreshToken
    return store.dispatch("auth/refreshToken").then(authResponse => {

      instance.defaults.headers.common['Authorization'] = 'Bearer ' + authResponse.data.access_token;
      originalRequest.headers['Authorization'] = 'Bearer ' + authResponse.data.access_token

      return instance(originalRequest)

    })
      .catch(err => {
        console.log(err)

        // Ritorno al login
        // NOTA: se il token non è stato rinnovato dal refresh token, non posso fare la dispatch per registrare il logout,
        //       ma devo uscire per forza
        storage.logout()

      });
  }

  store.commit('app/setLoading', false)

  console.log("response interceptor " + error)

  // Messaggio errore
  store.commit('app/showMessage', error)

  return Promise.reject(error);

})

export default instance