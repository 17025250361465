import request from '@/services/interceptor.js'



export const get_cred = () => {
    return request({
        url: '/app/preventivi/login',
        method: 'get',
        loadingBar: true
    })
};
export const get_comunicazioni = () => {
    return request({
        url: '/app/home/comunicazioni/',
        method: 'get'
    })
};




// -----------------------------
// QrCode
// -----------------------------
export const get_qrCodeDati = (par) => {
    return request({
        url: '/app/qrcode/' + par,
        method: 'get',
        loadingBar: true
    })
};

export const get_ordineDownload = par => {
    return request({
        url: '/app/qrcode/ordine/documenti/download',
        method: 'post',
        data: par,
        responseType: 'blob'
    })
};

export const save_faseProduzione = par => {
    return request({
        url: '/app/qrcode/local/fasi',
        method: 'post',
        data: par,
    })
};

export const del_faseProduzione = par => {
    return request({
        url: '/app/qrcode/local/fasi',
        method: 'delete',
        data: par,
    })
};

export const get_cercaOrdine = par => {
    return request({
        url: '/app/qrcode/local/cerca/' + par,
        method: 'get'
    })
};


// -----------------------------
// Infoazienda
// -----------------------------
export const login_infoazienda = par => {
    return request({
        url: '/app/infoazienda/login/' + par,
        method: 'get'
    })
}

