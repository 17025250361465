
const app = {
    namespaced: true,

    state: {
        loading: false,
        message: '',
    },

    mutations: {

        setLoading(state, vis) {
            state.loading = vis
        },

        showMessage(state, data){
            state.message=data

        },
    },


}

export default app