var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"icon":"","color":"black"},on:{"click":_vm.caricaDati}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$store.getters['user/traduci']('Aggiorna')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black","loading":_vm.loadingPrint,"disabled":_vm.loadingPrint},on:{"click":_vm.stampa}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("print")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$store.getters['user/traduci']('Stampa')))])]),_c('div',{staticStyle:{"width":"130px","margin-left":"10px"}},[_c('v-select',{attrs:{"items":_vm.filtriRicerca,"label":"Ordini"},on:{"input":_vm.caricaDati},model:{value:(_vm.filtroSel),callback:function ($$v) {_vm.filtroSel=$$v},expression:"filtroSel"}})],1),(_vm.role=='Agente' || _vm.role=='AgenteInterno' || _vm.role=='Interno')?_c('div',{staticStyle:{"width":"200px","margin-left":"10px"}},[_c('AutoCompleteClienti',{attrs:{"origine":"statoOrdini","dati":_vm.arrClienti},on:{"clienteSelezionato":_vm.clienteSelezionato}})],1):_vm._e(),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-0 pb-4",attrs:{"append-icon":"search","label":"Cerca","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dati,"search":_vm.search,"options":_vm.pagination,"no-data-text":'',"no-results-text":'Nessun dato trovato',"item-key":"NumOrdine","show-expand":"","single-expand":"","hide-default-header":"","mobile-breakpoint":0,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:options":function($event){_vm.pagination=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('tr',{staticClass:"grey lighten-2",staticStyle:{"height":"30px","font-size":"12px"}},_vm._l((headers),function(header){return (!(_vm.nascondiConsegnaPrevista && header.value=='DataConsegnaCliente'))?_c('th',{key:header.text,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changeSort(header.value)}}},[_vm._v(" "+_vm._s(_vm.$store.getters['user/traduci'](header.text))+" ")]):_vm._e()}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',{class:{'blue darken-3': (item.NumOrdine == _vm.selectedRowDettaglio.NumOrdine), 'white--text': (item.NumOrdine == _vm.selectedRowDettaglio.NumOrdine)},staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.visDocumenti(item, isExpanded)}}},[_c('td',{staticClass:"pa-0",staticStyle:{"width":"50px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(isExpanded ? "keyboard_arrow_down" : "keyboard_arrow_right"))])],1)],1),_c('td',{staticStyle:{"width":"60px","padding":"10px","text-align":"left"}},[_vm._v(" "+_vm._s(item.NumOrdine)+" ")]),_c('td',{staticStyle:{"width":"60px","padding":"5px","text-align":"center","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.DataOrdine)+" ")]),_c('td',{staticStyle:{"padding":"2px","text-align":"center"}},[_vm._v(" "+_vm._s(item.RagSoc)+" ")]),_c('td',{staticStyle:{"padding":"2px","text-align":"center"}},[_vm._v(" "+_vm._s(item.Riferimento)+" ")]),_c('td',{staticStyle:{"width":"50px","padding":"10px","text-align":"center"}},[_vm._v(" "+_vm._s(item.Qta)+" ")]),_c('td',{staticStyle:{"width":"70px","padding":"0px","text-align":"center"}},[_vm._v(" "+_vm._s(item.DescSerie)+" ")]),_c('td',{staticStyle:{"width":"70px!important","padding":"0px","text-align":"center"}},[_vm._v(" "+_vm._s(item.CodColore)+" ")]),(!_vm.nascondiConsegnaPrevista)?_c('td',{staticStyle:{"padding":"0px","text-align":"center"}},[_vm._v(" "+_vm._s(item.DataConsegnaCliente)+" ")]):_vm._e(),_c('td',{staticStyle:{"padding":"20px","text-align":"center"}},[_vm._v(" "+_vm._s(item.SettimanaConsegna)+" ")]),_c('td',{staticStyle:{"width":"50px","padding":"5px","text-align":"center"}},[_c('v-progress-circular',{attrs:{"size":50,"width":7,"rotate":-90,"value":item.StatoPerc,"color":_vm.getColorePerc(item)}},[_vm._v(" "+_vm._s(item.StatoPerc)+" ")])],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticStyle:{"min-height":"160px","background-color":"#fafafa"}},[_c('v-container',{attrs:{"fluid":"","grid-list-md":"","lighten-5":""}},[_c('v-row',{attrs:{"wrap":""}},[(_vm.loadingDocument)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_vm._e(),_vm._l((_vm.documenti),function(item){return (_vm.loadingDocument==false)?_c('v-col',{key:item.NomeDocumento},[_c('v-card',{attrs:{"width":"140px","height":"170px","shaped":""}},[_c('v-responsive',{staticClass:"pa-2",staticStyle:{"text-align":"center"},attrs:{"height":"90px"}},[_c('img',{staticStyle:{"width":"60px","margin":"auto"},attrs:{"src":item.UrlImg}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","small":"","disabled":_vm.attesaDownload},on:{"click":function($event){return _vm.scaricaFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("get_app")])],1)]}}],null,true)},[_c('span',[_vm._v("Scarica")])])],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('span',{staticClass:" black--text pl-1 pt-1",staticStyle:{"font-size":"14px!important"},domProps:{"textContent":_vm._s(item.Descrizione)}})])],1)],1):_vm._e()})],2)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }