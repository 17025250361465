<template>
  <v-container>
    <v-row row>
      <v-col xs12 sm6 offset-sm3>
        <v-card class="elevation-0">
          <v-toolbar color="#F2F2F2" tabs flat>
            <v-img position="left" :src="require('@/assets/img/logo_small.png')" contain width="100"
              height="50"></v-img>

            <v-spacer></v-spacer>

            <div>
              <v-progress-circular :size="40" color="blue" indeterminate v-if="attesa"></v-progress-circular>

              <v-toolbar-title v-if="!attesa" style="color: #155ca4">
                <v-icon left color="#155CA4">bar_chart</v-icon>Stato ordini
              </v-toolbar-title>
            </div>

          </v-toolbar>

          <v-tabs fixed-tabs color="transparent" v-model="tabs">
            <v-tabs-slider color="#155CA4"></v-tabs-slider>

            <v-tab :href="'#tab1'" class="black--text"> Dati </v-tab>
            <v-tab :href="'#tab2'" class="black--text"> Documenti </v-tab>
          </v-tabs>

          <v-tabs-items class="white" v-model="tabs">
            <!-- Dati ordine -->
            <v-tab-item :value="'tab1'" key="1">
              <v-list three-line>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon color="#155CA4">label</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Numero ordine</v-list-item-title>
                    <v-list-item-subtitle>{{
                      dati.NumOrdine
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>Data</v-list-item-title>
                    <v-list-item-subtitle>{{
                      dati.DataOrdine
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-avatar></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Stato avanzamento</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-progress-circular :size="50" :width="7" :rotate="-90" :value="dati.StatoPerc"
                        v-bind:color="getColorePerc(dati.StatoPerc)">
                        {{ dati.StatoPerc }}
                      </v-progress-circular>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-avatar></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Riferimento</v-list-item-title>
                    <v-list-item-subtitle>{{
                      dati.Riferimento
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>Qta</v-list-item-title>
                    <v-list-item-subtitle>{{ dati.Qta }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon color="#155CA4">view_list </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Serie</v-list-item-title>
                    <v-list-item-subtitle>{{
                      dati.CodFamiglia
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>Colore</v-list-item-title>
                    <v-list-item-subtitle>{{
                      dati.CodColore
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon color="#155CA4">event</v-icon>
                  </v-list-item-avatar>
                  <!--  <v-list-tile-content>
                  <v-list-tile-title>Data consegna prevista</v-list-tile-title>
                  <v-list-tile-sub-title>{{ dati.DataConsegnaCliente }}</v-list-tile-sub-title>
                </v-list-tile-content> -->
                  <v-list-item-content>
                    <v-list-item-title>Settimana</v-list-item-title>
                    <v-list-item-subtitle>{{
                      dati.SettimanaConsegna
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-tab-item>

            <!-- Documenti-->
            <v-tab-item :value="'tab2'" key="2">
              <v-container grid-list-sm fluid lighten-5>
                <v-row wrap>
                  <v-col xs4 d-flex v-for="item in documenti" :key="item.NomeDocumento">
                    <v-card width="140px" height="170px" shaped>
                      <v-responsive style="text-align: center" class="pa-2">
                        <img src="~@/assets/img/pdf_big.png" style="width: 60px" />

                        <v-btn fab icon small v-on:click="scaricaFile(item)">
                          <v-icon>get_app</v-icon>
                        </v-btn>
                        <div>{{ item.Descrizione }}</div>
                      </v-responsive>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get_qrCodeDati, get_ordineDownload } from "@/api/api.app.js";

export default {
  props: {
    token: { type: String, default: "" },
    apiKey: { type: String, default: "" },
  },

  data: () => ({
    dati: { NumOrdine: "" },
    documenti: [],

    attesa: false,
    tabs: "tab1",

    docSel: {},
  }),

  methods: {
    async caricaDati() {
      if (this.token.length == 0) return;

      this.attesa = true;

      try {
        // Reset
        this.dati = {};
        this.documenti = [];

        let par = "ordine/" + this.token + "?api_key=" + this.apiKey;

        let res = await get_qrCodeDati(par);
        this.dati = res.data.StatoOrdine;
        this.documenti = res.data.Documenti;
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    getColorePerc(valore) {
      if (valore == 100) return "green accent-4";
      else if (valore >= 60) return "light-blue accent-3";
      else if (valore >= 20) return "amber darken-1";
      else return "grey lighten-1";
    },

    async scaricaFile(item) {
      this.attesa = true;

      try {
        let res = await get_ordineDownload(item);

        this.$functionsApp.downloadFileBlob(res.data, item.NomeDocumento);
      } catch (error) {
        console.log(error);
      }

      this.attesa = false;
    },
  },
  mounted() {
    this.caricaDati();
  },
};
</script>