<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    @keydown.esc="chiudi()"
    width="500"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark v-on:click="chiudi()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Allegati</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text v-on:click="salva()" :disabled="attesa" :loading="attesa"
            >Salva
            <v-icon right>save</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$store.getters['user/traduci']('Descrizione')"
                v-model="datiAllegati.Descrizione"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="10">
              <v-text-field
                label="Allegato"
                v-model="datiAllegati.Documento"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="ml-0" fab small>
                    <v-icon @click="apri()" :loading="attesaApri"
                      >file_download</v-icon
                    >
                  </v-btn>
                </template>
                <span>Scarica</span>
              </v-tooltip>
            </v-col>

            <v-col xs12 sm12>
              <v-card-text>
                <label class="file-select">
                  <div class="select-button">
                    <span v-if="file">File da importare : {{ file.name }}</span>
                    <span v-else>Seleziona File ...</span>
                  </div>
                  <input
                    type="file"
                    @change="handleFileChange"
                    ref="inputFile"
                  />
                </label>
                <div>
                  <v-alert
                    :value="!!alertElaborazione"
                    type="success"
                    transition="scale-transition"
                  >
                    {{ alertElaborazione }}
                  </v-alert>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  get_allegatiOrdine,
  new_allegatiOrdine,
  upload_allegati,
  download_allegati,
} from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    prog: { type: Number, default: 0 },
    ordine: { type: Boolean, default: false },
  },

  data: () => ({
    datiAllegati: {},
    datiAllegati_empty: {
      Prog: 0,
      Descrizione: "",
      Documento: "",
    },

    attesa: false,
    aggiornaGriglia: false,

    file: "",
    loading: false,
    alertElaborazione: "",
    attesaApri: false,

    textRules: [(v) => !!v || "Il campo è obbligatorio"],
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      // NOTA: quando apro si attiva la validazione
      this.$nextTick(() => this.$refs.form.resetValidation());

      // Reset
      this.datiAllegati = Object.assign({}, this.datiAllegati_empty);
      this.file = "";

      // Controllo nuovo
      if (this.prog == 0) {
        this.datiAllegati.IDPrev = this.idPrev;
        this.datiAllegati.Prog = this.prog;

        return;
      }

      let param = this.idPrev + "/" + this.prog;

      try {
        let res = await get_allegatiOrdine(param);

        this.datiAllegati = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async salva() {
      // Validazione
      if (this.$refs.form.validate() == false) {
        return;
      }

      // Sul nuovo devo mettere l'allegato
      if (this.prog == 0 && !!this.file == false) {
        this.$toast.alert("Allegato non inserito.");
        return;
      }

      // Attesa
      this.attesa = true;

      try {
        // Controllo presenza allegati
        if (!!this.file) {
          let formData = new FormData();
          formData.append("file", this.file);

          let resUpload = await upload_allegati(this.idPrev, formData);
          if (resUpload.data != "OK") {
            this.$toast.alert(resUpload.data);
            return;
          }

          this.datiAllegati.Documento = this.file.name;
        }

        this.datiAllegati.ordine = this.ordine;

        let res = await new_allegatiOrdine(this.datiAllegati);
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.aggiornaGriglia = true;

      // Fine attesa
      this.attesa = false;

      // Chiudo
      this.chiudi();
    },

    async apri() {
      if (this.datiAllegati.Documento.length == 0) return;

      this.attesaApri = true;
      try {
        let res = await download_allegati(this.idPrev + "/" + this.prog);

        this.$functionsApp.downloadFileBlob(
          res.data,
          this.datiAllegati.Documento
        );
      } catch (error) {
        // NOTA: non riesco ad intercettare l'errore da un blob
        this.$toast.alert("Si è verificato un errore.");
        console.log(error);
      }

      this.attesaApri = false;
    },

    handleFileChange(e) {
      // Whenever the file changes, emit the 'input' event with the file data.
      this.file = e.target.files[0];

      // Reset value (serve nel caso selezionano lo stesso file più volte)
      this.$refs.inputFile.value = "";
    },

    chiudi() {
      this.$emit("chiudiDialog", this.aggiornaGriglia);

      // Reset
      this.aggiornaGriglia = false;
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },
};
</script>

<style scoped>
.file-select > .select-button {
  padding: 1rem;

  color: white;
  background-color: #2196f3;

  border-radius: 0.3rem;

  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.file-select > input[type="file"] {
  display: none;
}
</style>