import ToastComponent from "./toast.vue"
import {
  EventBus
} from "./toastEventBus"


const defaultOptions = {};

const ToastPlugin = {
  install(Vue, options) {

    // Merge  opzioni default con arg options
    let userOptions = { ...defaultOptions, ...options };

    // Registro il componente
    Vue.component('toastFixed', ToastComponent)

    // Memorizzo le opzioni
    Vue.prototype.$toastOptions = userOptions

    Vue.prototype.$toastFixed = {

      alert(msg) {
        EventBus.$emit('ToastMessage', 'alert', msg)
      },

      error(msg) {
        EventBus.$emit('ToastMessage', 'error', msg)
      },

      info(msg) {
        EventBus.$emit('ToastMessage', 'info', msg)
      },

      success(msg) {
        EventBus.$emit('ToastMessage', 'success', msg)
      },

      close() {
        EventBus.$emit('ToastMessage', '', '')
      },

    }

  }
}

export default ToastPlugin