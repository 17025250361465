
import request from '@/services/interceptor.js'





// -----------------------------
//  Utente 
// -----------------------------
export const get_datiprofiloUtente = (par) => {
    return request({
        url: '/utenti/dati/' + par,
        method: 'get',
        loadingBar: false
    })
};
export const get_datiUserInfo = () => {
    return request({
        url: '/utenti/info',
        method: 'get',
        loadingBar: true
    })
};
export const save_mailUser = par => {
    return request({
        url: '/utenti/savemail',
        method: 'post',
        data: par,
        loadingBar: false
    })
};

