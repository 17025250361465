<template src="./pariCodice.html"></template>

<script>
import {
  get_dettaglioVariantiPariCodice,
  set_dettaglioVariantiPariCodice,
} from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    scheda: { type: Number, default: 0 },
    prog: { type: Number, default: 0 },
    sezione: { type: Number, default: 0 },
    progVariante: { type: Number, default: 0 },
    codArticolo: { type: String, default: "" },
    tipo: { type: String, default: "" },
  },

  data: () => ({
    attesa: false,
    attesaCaricamento: false,

    selected: [],
    headers: [
      { text: "Prog", value: "Prog" },
      { text: "codSerie", value: "CodSerie" },
      { text: "Progetto", value: "CodProgetto" },
      { text: "Descrizione", value: "DescProgetto" },
    ],
    dati: [],

    radioOpearazione: "",
  }),

  methods: {
    async caricaDati() {
      this.dati = [];
      this.selected = [];
      this.radioOpearazione = "aggiorna";

      if (this.dialog == false) return;

      this.attesaCaricamento = true;

      try {
        let res = await get_dettaglioVariantiPariCodice(this.idPrev);

        this.dati = res.data;

        // Sui coprifilo visualizzo solo quelli di medesima serie
        if (this.tipo == "coprifilo_interno" || this.tipo == "coprifilo_esterno") {
          let serieSel = this.dati.find(f => f.Prog == this.prog).CodSerie
          this.dati = this.dati.filter(f => f.CodSerie == serieSel)
        }

      } catch (error) {
        console.log(error);
      }

      this.attesaCaricamento = false;
    },

    async salva() {
      if (this.selected == false) {
        this.$toast.alert("Nessun rigo selezionato");
        return;
      }


      // Conferma
      let confirm = await this.$confirm.open(
        "Applica articolo",
        "Vuoi eseguire l'operazione sulle posizioni selezionate ?"
      );
      if (!confirm) return;

      this.attesa = true;

      let contData = 0;

      // Controllo coprifili
      if (this.tipo == "coprifilo_interno" || this.tipo == "coprifilo_esterno") {

        try {
          let par =
            this.radioOpearazione +
            "/" +
            this.idPrev +
            "/" +
            this.scheda +
            "/" +
            this.prog +
            "/" +
            this.tipo

          let res = await set_dettaglioVariantiPariCodice(par, this.selected);

          contData = res.data;
        } catch (error) {
          console.log(error);
          this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
        }

      }
      else {

        try {
          let par =
            this.radioOpearazione +
            "/" +
            this.idPrev +
            "/" +
            this.scheda +
            "/" +
            this.prog +
            "/" +
            this.sezione +
            "/" +
            this.progVariante;

          let res = await set_dettaglioVariantiPariCodice(par, this.selected);

          contData = res.data;
        } catch (error) {
          console.log(error);
          this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
        }
      }

      this.attesa = false;
      this.chiudi(contData);
    },

    chiudi(res) {
      this.$emit("chiudiDialog", res);
    },

    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.dati.slice();
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog(val) {
      this.caricaDati();
    },
  },
};
</script>