<template>
  <v-dialog v-model="dialog" persistent max-width="400" @keydown.esc="chiudi(false)">
    <v-card height="260">
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark v-on:click="chiudi()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Coprifili {{ tipo }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="elimina">Elimina
            <v-icon dark right>delete</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div style="
          width: 100px;
          height: 100px;
          margin: auto;
          margin-top: 50px;
          border: 1px solid grey;
          background-color: lightgrey;
        ">
        <div style="position: absolute; margin-top: -45px; margin-left: 15px">
          Superiore
        </div>
        <v-checkbox label="" style="
            width: 20px;
            position: absolute;
            margin-top: -30px;
            margin-left: 40px;
          " value="SUP" v-model="selezionati">
        </v-checkbox>

        <div style="position: absolute; margin-top: 35px; margin-left: 130px">
          Destro
        </div>
        <v-checkbox label="" style="
            width: 20px;
            position: absolute;
            margin-top: 30px;
            margin-left: 100px;
          " value="DX" v-model="selezionati">
        </v-checkbox>

        <div style="position: absolute; margin-top: 130px; margin-left: 25px">
          Inferiore
        </div>
        <v-checkbox label="" style="
            width: 20px;
            position: absolute;
            margin-top: 100px;
            margin-left: 40px;
          " value="INF" v-model="selezionati">
        </v-checkbox>

        <div style="position: absolute; margin-top: 35px; margin-left: -80px">
          Sinistro
        </div>
        <v-checkbox label="" style="
            width: 20px;
            position: absolute;
            margin-top: 30px;
            margin-left: -25px;
          " value="SX" v-model="selezionati">
        </v-checkbox>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
    tipo: { type: String, default: "" },
  },

  data: () => ({
    selezionati: [],
  }),

  methods: {
    async elimina() {
      // NOTA: il valore delle checkbox (SUP-DX-INF-SX) è usato dentro il dettaglio -> funnzione eliminaCoprifili

      // Controllo presenza selezionata
      if (this.selezionati.length == 0) {
        this.$toast.alert("Nessun coprifilo selezionato.");
        return;
      }

      let res = await this.$confirm.open(
        "Coprifili",
        "Vuoi eliminare i coprifili selezionati ?"
      );

      if (!res) return;

      // Chiudo la form
      this.chiudi();

      this.$emit("eliminaCoprifili", this.tipo, this.selezionati);
    },

    chiudi() {
      this.$emit("chiudiDialog");
    },
  },


};
</script>