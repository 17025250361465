<template src="./sconti.html"></template>

<script>
import { save_totaliTestata, get_totaliTestata } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    bloccato: { type: Boolean, default: false },
  },

  data: () => ({
    dati: {},
    dati_empty: {
      Sconto: 0,
      TotPattuito: 0,
      TotTrasporto: 0,
      TotLibero: 0,
      DescTrasporto: "",
      DescLibero: "",
    },

    aggiornaGriglia: false,
    attesa: false,

    textRules: [(v) => !!v || "Il campo è obbligatorio"],
    numericRules: [(v) => (!!v || v === 0 ? true : "Il campo è obbligatorio")],
  }),

  methods: {
    async caricaDati() {
      // Controllo dialog
      if (this.dialog == false) return;

      // NOTA: quando apro si attiva la validazione
      this.$nextTick(() => this.$refs.form.resetValidation());

      // Reset
      this.dati = Object.assign({}, this.dati_empty);

      let res = await get_totaliTestata(this.idPrev);

      this.dati = res.data;
    },

    async salva() {
      // Validazione
      if (this.$refs.form.validate() == false) {
        return;
      }

      // Attesa
      this.attesa = true;

      try {
        let res = await save_totaliTestata(this.dati);

        this.aggiornaGriglia = true;

        this.chiudi();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
      this.attesa = false;
    },

    chiudi() {
      this.$emit("chiudiDialog", this.aggiornaGriglia);

      // Reset
      this.aggiornaGriglia = false;
    },
  },
  mounted() {
    this.caricaDati();
  },
  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },
};
</script>
