<template >
  <v-data-table :headers="headers" :items="dati" :search="search" :options.sync="pagination" :no-data-text="''"
    :no-results-text="'Nessun dato trovato'" item-key="CodCliente" show-expand single-expand hide-default-header   >

    <!--Header -->
    <template v-slot:header="{ props: { headers } }">
      <tr class="grey lighten-2" style="height:30px;  font-size: 12px;">
        <th v-for="header in headers" :key="header.text">
          <v-icon small>arrow_upward</v-icon>
          {{ header.text }}
        </th>
      </tr>
    </template>

    <template v-slot:expanded-item="{  headers, item }">
      <td :colspan="headers.length">
        <div style="min-height: 160px; " :key="item.CodCliente">
          <Profilo :codCliente="item.CodCliente"></Profilo>
        </div>
      </td>
    </template>


  </v-data-table>

</template>

<script>
import Profilo from "@/views/Profilo";

export default {
  components: {
    Profilo,
  },

  props: {
    dati: { type: Array, default: () => [] },
  },

  data: () => ({
    loadingDati: false,

    headers: [
      { text: "Rag.Sociale", value: "RagSoc" },
      { text: "Località", value: "Localita" },
    ],

    search: "",
    pagination: {
      itemsPerPage: 200,
    },
  }),

  methods: {


  },

};
</script>
