<template >
  <v-layout row justify-center>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" persistent @keydown.esc="chiudi()" width="500">

      <v-card>

        <v-toolbar dark color="primary" dense>
          <v-btn icon dark v-on:click="chiudi()">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Voci extra</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text v-on:click="salva()" :disabled="attesa" :loading="attesa"
              v-show="!bloccato && !gestioneAutomatica">Salva
              <v-icon right>save</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-form ref="form">

          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12">
                <v-text-field label="CodArticolo" v-model="datiVociExtra.CodArticolo" outlined append-icon="search"
                  @click:append="zoomArticoli()" hide-details :disabled="gestioneAutomatica">
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-2">

                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider color="primary"></v-tabs-slider>

                  <v-tab :key="1" style="font-weight: bold">{{ $store.getters['user/traduci']('Descrizione') }}</v-tab>
                  <v-tab :key="2" style="font-weight: bold">{{ $store.getters['user/traduci']('Descrizione FR') }}
                  </v-tab>
                  <v-tab :key="3" style="font-weight: bold">{{ $store.getters['user/traduci']('Descrizione EN') }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="pt-2">
                  <v-tab-item :key="1">
                    <v-textarea name="input-7-4" label="Descrizione" outlined placeholder=" " no-resize
                      v-model="datiVociExtra.Descrizione" hide-details :disabled="modificaDescrizione"></v-textarea>
                  </v-tab-item>

                  <v-tab-item :key="2">
                    <v-textarea name="input-7-4" label="Descrizione" outlined placeholder=" " no-resize
                      v-model="datiVociExtra.DescrizioneFR" hide-details :disabled="modificaDescrizione"></v-textarea>
                  </v-tab-item>

                  <v-tab-item :key="3">
                    <v-textarea name="input-7-4" label="Descrizione" outlined placeholder=" " no-resize
                      v-model="datiVociExtra.DescrizioneEN" hide-details :disabled="modificaDescrizione"></v-textarea>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>

              <v-col cols="12" v-if="coprifili || colore">
                <v-select :items="colori" label="Colore" outlined :rules="coprifili ? textRules : [true]"
                  item-text="Descrizione" item-value="CodArticolo" v-model="datiVociExtra.Colore"
                  @change="calcolaPrezzoCoprifilo" :loading="attesaColori" :disabled="gestioneAutomatica"></v-select>
              </v-col>

              <v-col cols="12" class="mt-2">
                <v-row row>
                  <v-col xs4 sm6>
                    <v-text-field :label="$store.getters['user/traduci']('Q.tà')" v-model="datiVociExtra.Qta"
                      :rules="numericRules" type="number" reverse outlined required :disabled="gestioneAutomatica">
                    </v-text-field>
                  </v-col>

                  <v-col xs4 sm6>
                    <v-text-field :label="$store.getters['user/traduci']('Prezzo')" v-model="datiVociExtra.Prezzo"
                      :rules="numericRules" type="number" prefix="€" reverse outlined :disabled="gestioneAutomatica">
                    </v-text-field>
                  </v-col>

                  <v-col xs4 sm6>
                    <v-text-field :label="$store.getters['user/traduci']('Sconto')" v-model="datiVociExtra.Sconto"
                      :rules="numericZeroRules" mask="###" reverse outlined :disabled="gestioneAutomatica">
                    </v-text-field>
                  </v-col>
                </v-row>

              </v-col>

            </v-row>

          </v-container>

          <v-alert class="mb-0" type="info" v-if="gestioneAutomatica">Gestita automaticamente dal programma.</v-alert>

        </v-form>
      </v-card>

      <dialog-articoli :dialog="dialogArticoli" @chiudiDialog="closeDialogArticoli" :vociExtra="true" :idPrev="idPrev"
        class="ma-0">
      </dialog-articoli>
    </v-dialog>
  </v-layout>
</template>

<script>
import {
  get_vociExtraOrdine,
  new_rigoVociExtra,
  get_coloriCoprifiloVociExtra,
  get_prezzoCoprifiloVociExtra,
} from "@/api/api.ordini.js";

import DialogArticoli from "@/components/dialog/ordini/ArticoliDettaglio";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    prog: { type: Number, default: 0 },
    bloccato: { type: Boolean, default: false },
  },

  components: {
    DialogArticoli,
  },

  data: () => ({
    datiVociExtra: {},
    datiVociExtra_empty: {
      Prog: 0,
      CodArticolo: "",
      Descrizione: "",
      DescrizioneFR: "",
      DescrizioneEN: "",
      Qta: 0,
      Prezzo: 0,
      Sconto: 0,
      Colore: "",
      CodMacroCategoria: "",
      Tipo: "",
    },

    aggiornaGriglia: false,
    attesa: false,
    attesaColori: false,

    dialogArticoli: false,

    textRules: [(v) => !!v || "Il campo è obbligatorio"],
    numericRules: [
      (v) => !!v || "Il campo è obbligatorio",
      (v) => v != 0 || "Il campo è obbligatorio",
    ],
    numericZeroRules: [
      (v) => (!!v || v === 0 ? true : "Il campo è obbligatorio"),
    ],

    colori: [],

    tab: 0,
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      // NOTA: quando apro si attiva la validazione
      this.$nextTick(() => this.$refs.form.resetValidation());

      // Reset
      this.datiVociExtra = Object.assign({}, this.datiVociExtra_empty);
      this.colori = [];
      this.tab = 0;

      // Controllo nuovo
      if (this.prog == 0) {
        this.datiVociExtra.IDPrev = this.idPrev;
        this.datiVociExtra.Prog = this.prog;

        return;
      }

      this.attesa = true;

      try {
        let param = this.idPrev + "/" + this.prog;
        let res = await get_vociExtraOrdine(param);

        this.datiVociExtra = res.data;

        if (this.colore) {
          this.caricaColori();
        }
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    async salva() {
      // Validazione
      if (this.$refs.form.validate() == false) {
        return;
      }

      // Attesa
      this.attesa = true;

      try {
        let res = await new_rigoVociExtra(this.datiVociExtra);

        this.aggiornaGriglia = true;

        // Chiudo
        this.chiudi();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      // Fine attesa
      this.attesa = false;
    },

    zoomArticoli() {
      this.dialogArticoli = true;
    },

    closeDialogArticoli(agg, data) {
      this.dialogArticoli = false;

      if (!agg)
        return

      this.datiVociExtra.CodArticolo = data.CodArticolo;
      this.datiVociExtra.Descrizione = data.Descrizione;
      this.datiVociExtra.DescrizioneFR = data.DescrizioneFR;
      this.datiVociExtra.DescrizioneEN = data.DescrizioneEN;
      this.datiVociExtra.Prezzo = data.Vendita;
      this.datiVociExtra.Sconto = data.Sconto;
      this.datiVociExtra.CodMacroCategoria = data.CodMacroCategoria;
      this.datiVociExtra.Colore = "";

      // Controllo coprifili
      if (this.coprifili) {
        this.datiVociExtra.Prezzo = 0;
        this.caricaColori();
      }
    },

    chiudi() {
      this.$emit("chiudiDialog", this.aggiornaGriglia);

      // Reset
      this.aggiornaGriglia = false;
    },

    async caricaColori() {
      // Reset
      this.colori = [];

      this.attesaColori = true;

      try {
        let data = {
          IDPrev: this.idPrev,
          CodArticolo: this.datiVociExtra.CodArticolo,
        };

        let res = await get_coloriCoprifiloVociExtra(data);

        this.colori = res.data;
      } catch (error) {
        console.log(error);
      }

      this.attesaColori = false;
    },

    async calcolaPrezzoCoprifilo() {
      try {
        let data = {
          IDPrev: this.idPrev,
          CodArticolo: this.datiVociExtra.CodArticolo,
          Colore: this.datiVociExtra.Colore,
        };

        let res = await get_prezzoCoprifiloVociExtra(data);

        this.datiVociExtra.Prezzo = res.data;
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },

  computed: {
    coprifili() {
      return this.datiVociExtra.CodMacroCategoria === "COPRIFILO";
    },

    colore() {
      return (
        Object.keys(this.datiVociExtra).length > 0 &&
        this.datiVociExtra.Colore.length > 0
      );
    },

    gestioneAutomatica() {
      return (
        Object.keys(this.datiVociExtra).length > 0 &&
        this.datiVociExtra.Tipo.length > 0
      );
    },

    getRole() {
      return this.$store.getters["user/getRole"];
    },

    modificaDescrizione() {
      if (this.gestioneAutomatica) return true;

      // Internamente possono modificare
      if (this.getRole.toLowerCase() === "Interno".toLowerCase()) return false;

      if (
        this.datiVociExtra.CodArticolo &&
        this.datiVociExtra.CodArticolo != "LIBERA"
      )
        return true;

      return false;
    },
  },
};
</script>
