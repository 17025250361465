<template>
  <v-card class="elevation-0">
    <v-app-bar prominent>
      <v-img position="left" :src="require('@/assets/img/logo_small.png')" contain width="100" height="50"></v-img>

      <v-app-bar-title>
        <v-text-field outlined label="Cerca ordine" class="pt-3 ml-2 mr-4" v-model="numOrdRicerca"
          v-on:keyup.enter="cerca()" @click:append="cerca()" append-icon="search" hide-details  v-if="user" >
        </v-text-field>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <div>
        <h3>
          {{ getNomeUtente }}
        </h3>
      </div>

      <v-btn v-if="user" icon class="ml-1" @click="logout()">
        <v-icon left>logout</v-icon>
      </v-btn>

    </v-app-bar>


    <div class="mx-auto text-center" v-if="!user">
      Inquadra il qrcode del tuo utente:
      <qrcode-stream @decode="onDecode" style="margin: auto; width: 300px"></qrcode-stream>
    </div>

    <div v-if="user">
      <v-tabs show-arrows  center-active v-model="tabs">
        <v-tabs-slider color="#155CA4"></v-tabs-slider>

        <v-tab :href="'#tab1'" class="black--text"> Dati </v-tab>
        <v-tab :href="'#tab2'" class="black--text"> Fase </v-tab>
        <v-tab :href="'#tab3'" class="black--text"> Allegati </v-tab>
        <v-tab :href="'#tab4'" class="black--text"> Fornitori </v-tab>
      </v-tabs>

      <v-tabs-items class="white" v-model="tabs">

        <!-- Dati ordine -->
        <v-tab-item :value="'tab1'" key="1">
          <v-list three-line>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="#155CA4">label</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Numero ordine</v-list-item-title>
                <v-list-item-subtitle>{{ dati.NumOrdine }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Data</v-list-item-title>
                <v-list-item-subtitle>{{ dati.DataOrdine }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-avatar> </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Stato avanzamento</v-list-item-title>
                <v-list-item-subtitle>
                  <v-progress-circular :size="50" :width="7" :rotate="-90" :value="dati.StatoPerc"
                    v-bind:color="getColorePerc(dati.StatoPerc)">
                    {{ dati.StatoPerc }}
                  </v-progress-circular>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-avatar></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Riferimento</v-list-item-title>
                <v-list-item-subtitle>{{
                  dati.Riferimento
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Qta</v-list-item-title>
                <v-list-item-subtitle>{{ dati.Qta }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="#155CA4">view_list </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Serie</v-list-item-title>
                <v-list-item-subtitle>{{
                  dati.CodFamiglia
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Colore</v-list-item-title>
                <v-list-item-subtitle>{{ dati.CodColore }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="#155CA4">event</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Settimana</v-list-item-title>
                <v-list-item-subtitle>{{
                  dati.SettimanaConsegna
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>

        <!-- Fase -->
        <v-tab-item :value="'tab2'" key="4">
          <v-card class="mx-auto pt-4">

            <v-list>
              <v-list-item-group v-model="faseSelezionata" color="red">
                <template v-for="(item, i) in fasi">
                  <v-list-item :key="i">
                    <v-list-item-icon>
                      <v-icon color="primary" v-if="item.DataFatto">check_circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.Fase"></v-list-item-title>
                      <div v-show="showNote">
                        Note:{{ item.Note }}
                      </div>
                    </v-list-item-content><v-list-item-action-text class="caption black--text"
                      v-text="$i2kFunctions.formatISODate(item.DataFatto)"></v-list-item-action-text>
                    <v-icon v-if="item.Note" class="mx-2" @click="showNote = !showNote">event_note</v-icon>

                    <v-list-item-action>
                      <v-btn icon @click="eliminaFase(i)"><v-icon>delete</v-icon></v-btn></v-list-item-action>
                  </v-list-item><v-divider :key="i.toString() + 'divider'"></v-divider></template>
              </v-list-item-group>
            </v-list>

            <v-textarea filled class="mx-4" v-if="user" label="Note" outlined auto-grow v-model="dati.Note"
              hide-details></v-textarea>

            <v-btn v-if="user" @click="dialogFoto = true" block outlined class="my-4">Scatta
              foto<v-icon>camera</v-icon></v-btn>

            <!-- Camera -->
            <v-dialog v-model="dialogFoto">
              <v-card>
                <v-toolbar dark color="primary" dense>
                  <v-btn icon dark v-on:click="dialogFoto = false">
                    <v-icon>close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Scatta foto</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn text v-on:click="scattaFoto()">Scatta foto
                      <v-icon right>camera</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <camera :numOrdine="dati.NumOrdine" :apiKey="apiKey" :idUtente="getIdUtente()" :dialog="dialogFoto"
                  @picture="pictureHandler" ref="cameraFoto"></camera>
              </v-card>
            </v-dialog>

            <v-footer app color="primary"><v-spacer></v-spacer><v-btn
                @click="salvaFase()">Salva<v-icon>save</v-icon></v-btn></v-footer>
          </v-card>
        </v-tab-item>

        <!-- Allegati -->
        <v-tab-item :value="'tab3'" key="2">
          <v-container grid-list-sm fluid lighten-5>
            <v-row wrap>
              <v-col xs4 d-flex v-for="item in documenti" :key="item.NomeDocumento">
                <v-card width="140px" height="170px" shaped>
                  <v-responsive style="text-align: center" class="pa-2">
                    <img :src="item.UrlImg" style="width: 60px; margin: auto" />

                    <v-btn fab icon small v-on:click="scaricaFile(item)" v-show="
                      $i2kFunctions.getExtensionFile(item.NomeDocumento) ==
                      '.pdf'
                    ">
                      <v-icon>get_app</v-icon>
                    </v-btn>
                    <div>{{ item.Descrizione }}</div>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>
          </v-container></v-tab-item>

        <!-- Fornitori -->
        <v-tab-item :value="'tab4'" key="3">
          <v-data-table :headers="headerFornitori" :items="fornitori" disable-sort disable-pagination hide-default-footer>
            <!-- Personalizzo la colonna data -->
            <!-- eslint-disable-next-line -->
            <template v-slot:item.DataArrivo="{ item }">
              {{
                item.DataArrivo
                ? new Date(item.DataArrivo).toLocaleDateString("it-IT")
                : ""
              }}
            </template></v-data-table>

          <v-container grid-list-sm fluid lighten-5>
            <v-row wrap>
              <template v-for="forn in fornitori">
                <v-col xs4 d-flex v-for="item in forn.Documenti" :key="item.NomeDocumento">
                  <v-card width="140px" height="170px" shaped>
                    <v-responsive style="text-align: center" class="pa-2">
                      <img :src="item.UrlImg" style="width: 60px; margin: auto" />

                      <v-btn fab icon small v-on:click="scaricaFile(item)" v-show="
                        $i2kFunctions.getExtensionFile(item.NomeDocumento) ==
                        '.pdf'
                      ">
                        <v-icon>get_app</v-icon>
                      </v-btn>
                      <div>{{ item.Descrizione }}</div>
                    </v-responsive>
                  </v-card>
                </v-col></template>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import {
  get_qrCodeDati,
  get_ordineDownload,
  save_faseProduzione,
  del_faseProduzione,
} from "@/api/api.app.js";
import { QrcodeStream } from "vue-qrcode-reader";

import Camera from "@/components/ui/Camera.vue";

export default {
  components: {
    QrcodeStream,
    Camera,
  },

  props: {
    token: { type: String, default: "" },
    apiKey: { type: String, default: "" },
  },

  data: () => ({
    dati: {},
    documenti: [],
    fornitori: [],

    headerFornitori: [
      { text: "Rag.Sociale", value: "RagSoc" },
      { text: "Data arrivo", value: "DataArrivo" },
    ],

    tabs: "tab1",

    docSel: {},

    fasi: [],
    faseSelezionata: -1,

    dialogFoto: false,

    user: "",

    numOrdine: "",
    numOrdRicerca: "",

    showNote: false,

  }),

  methods: {
    async caricaDati() {
      if (this.numOrdine.length == 0) return;

      this.$wait.open();

      try {
        // Reset
        this.dati = {};
        this.documenti = [];
        this.fornitori = [];
        this.fasi = [];
        this.user = localStorage.getItem("userProduzione");
        this.faseSelezionata = -1;

        let par = "local/ordine/" + this.numOrdine + "?api_key=" + this.apiKey;

        let res = await get_qrCodeDati(par);

        this.dati = res.data.StatoOrdine;
        this.documenti = res.data.Documenti;
        this.fornitori = res.data.OrdiniFornitori;

        // Controllo utente per caricamento fasi
        if (this.user) this.caricaFasi();
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.$wait.close();

    },

    getColorePerc(valore) {
      if (valore == 100) return "green accent-4";
      else if (valore >= 60) return "light-blue accent-3";
      else if (valore >= 20) return "amber darken-1";
      else return "grey lighten-1";
    },

    async scaricaFile(item) {
      this.$wait.open();

      try {
        let res = await get_ordineDownload(item);

        // Controllo pdf (apertura nel browser)
        // NOTA: per non fare il download sul cellulare apro in una nuova scheda (funzionano solo i pdf)
        if (this.$i2kFunctions.getExtensionFile(item.NomeDocumento) == ".pdf") {
          this.$functionsApp.openPdfOnWeb(res.data);

          // Codice x il download
          //this.$functionsApp.downloadFileBlob(res.data, item.NomeDocumento);
        }
      } catch (error) {
        console.log(error);
      }

      this.$wait.close();
    },

    async onDecode(nomeUtente) {
      this.$wait.open();

      try {
        let par = "local/user/" + nomeUtente + "?api_key=" + this.apiKey;

        let res = await get_qrCodeDati(par);

        let ut = res.data + "$" + nomeUtente;

        // Memorizzo l'utente
        localStorage.setItem("userProduzione", ut);
        this.user = ut;

        // Carico le fasi
        this.caricaFasi();
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.$wait.close();
    },

    getIdUtente() {
      if (this.user) return parseInt(this.user.split("$")[0]);
      return 0;
    },

    async caricaFasi() {
      this.$wait.open();

      try {
        //Reset

        this.fasi = [];
        this.faseSelezionata = -1;
        this.dati.Note = "";
        this.dati.Image = "";

        let par =
          "local/fasi/" +
          this.dati.NumOrdine +
          "/" +
          this.getIdUtente() +
          "?api_key=" +
          this.apiKey;

        let res = await get_qrCodeDati(par);

        this.fasi = res.data;
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.$wait.close();
    },

    async salvaFase() {
      if (this.faseSelezionata == -1) {
        this.$toast.alert("Fase non selezionata");
        return;
      }

      this.$wait.open();

      try {
        let par = {
          NumOrdine: this.dati.NumOrdine,
          Fase: this.fasi[this.faseSelezionata].Fase,
          IdUtente: this.getIdUtente(),
          api_key: this.apiKey,
          Note: this.dati.Note,
          Image: this.dati.Image,
        };

        let res = await save_faseProduzione(par);
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.$wait.close();

      this.caricaFasi();
    },

    async eliminaFase(indice) {
      let confirm = await this.$confirm.open(
        "Elimina",
        "Confermi eliminazione fase ?"
      );
      if (!confirm) return;

      try {
        let par = {
          NumOrdine: this.dati.NumOrdine,
          Fase: this.fasi[indice].Fase,
          IdUtente: this.getIdUtente(),
          api_key: this.apiKey,
        };

        let res = await del_faseProduzione(par);

        this.caricaFasi();
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    scattaFoto() {
      this.$refs.cameraFoto.captureImage();
    },

    pictureHandler(image) {
      this.dialogFoto = false;
      this.dati.Image = image;
    },

    logout() {
      localStorage.removeItem("userProduzione");
      this.user = "";
      this.fasi = [];
    },

    async cerca() {
      if (!!this.numOrdRicerca == false) return;

      this.numOrdine = this.numOrdRicerca;

      this.caricaDati();
    },


  },

  created() {
    this.user = localStorage.getItem("userProduzione");
  },
  mounted() {
    this.numOrdine = this.token;

    this.caricaDati();
  },

  computed: {
    getNomeUtente() {
      if (this.user) return this.user.split("$")[1];

      return "";
    },
  },
};
</script>

