<template src="./serie.html"></template>

<script>
import { get_riepilogoSerie, new_riepilogoSerie } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    prog: { type: Number, default: 0 },
    bloccato: { type: Boolean, default: false },
  },

  data: () => ({
    dati: {},
    dati_empty: {
      Prog: 0,
      Descrizione: "",
      Percentuale: 0,
    },

    aggiornaGriglia: false,
    attesa: false,

    textRules: [(v) => !!v || "Il campo è obbligatorio"],
    numericRules: [
      (v) => !!v || "Il campo è obbligatorio",
      (v) => v != 0 || "Il campo è obbligatorio",
    ],
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      // NOTA: quando apro si attiva la validazione
      this.$nextTick(() => this.$refs.form.resetValidation());

      // Reset
      this.dati = Object.assign({}, this.dati_empty);

      // Controllo apertura
      if (this.dialog == false) return;

      let param = this.idPrev + "/" + this.prog;

      try {
        let res = await get_riepilogoSerie(param);

        this.dati = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async salva() {
      // Validazione
      if (this.$refs.form.validate() == false) {
        return;
      }

      // Attesa
      this.attesa = true;

      try {
        let res = await new_riepilogoSerie(this.dati);

        this.aggiornaGriglia = true;

        // Chiudo
        this.chiudi();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      // Fine attesa
      this.attesa = false;
    },

    chiudi() {
      this.$emit("chiudiDialog", this.aggiornaGriglia);

      // Reset
      this.aggiornaGriglia = false;
    },
  },
  mounted() {
    this.caricaDati();
  },
  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },
};
</script>
