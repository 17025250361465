<template>
  <div>
    <video v-if="!imageData.image" ref="video" class="camera-stream" />
    <img v-else :src="imageData.image" class="camera-stream" />
  </div>
</template>

<script>
export default {
  props: {
    numOrdine: { type: String, default: "" },
    apiKey: { type: String, default: "" },
    idUtente: { type: Number, default: 0 },
    dialog: { type: Boolean, default: false },
  },
  data() {
    return {
      mediaStream: null,
      imageData: {
        image: "",
      },
    };
  },

  methods: {
    initCamera() {
      if (!this.dialog) return;

      // Reset
      this.imageData.image = null;

      try {
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: "environment" } })
          .then((mediaStream) => {
            this.$refs.video.srcObject = mediaStream;
            this.$refs.video.play();
            this.mediaStream = mediaStream;
          });
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    stopCamera() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    },

    captureImage() {
      try {
        const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
        const imageCapture = new window.ImageCapture(mediaStreamTrack);
        let reader = new FileReader();
        return imageCapture.takePhoto().then((blob) => {
          reader.readAsDataURL(blob);
          reader.onload = () => {
            this.imageData.image = reader.result;

            this.$emit("picture", this.imageData.image);
          };
        });
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },
  },

  mounted() {
    this.initCamera();
  },

  watch: {
    dialog(value) {
      if (value) this.initCamera();
      else {
        this.stopCamera();
      }
    },
  },
};
</script>

<style scoped>
.icon-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 12px auto;
}
.camera-icon {
  width: 15%;
  vertical-align: middle;
  margin: auto;
}
.camera-stream {
  width: 100%;
}
</style>