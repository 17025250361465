<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="pa-1 justify-center text-center" flat>
          <div v-show="attesaVerifica" style="font-size: 20px">
            <v-progress-circular
              :size="40"
              color="blue"
              indeterminate
            ></v-progress-circular
            >Elaborazione in corso..
          </div>

          <v-alert
            :value="!!alertElaborazione"
            :type="tipo"
            transition="scale-transition"
            style="width: 100%"
            v-html="alertElaborazione"
          >
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { login_infoazienda } from "@/api/api.app.js";
import storage from "@/services/storage.js";

export default {
  data: () => ({
    alertElaborazione: "",
    tipo: "warning",
    attesaVerifica: false,

    cred: {
      username: "",
      password: "",
      ricorda: true,
    },
  }),

  methods: {
    async verificaToken() {
      try {
        // Controllo querystring
        if (this.checkQueryStringCode() == false) {
          this.alertElaborazione = "Token non valido";
          this.tipo = "warning";
          return;
        }

        this.attesaVerifica = true;

        // Attesa
        let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
        await wait(1800);

        // Controllo se è login attivo, pulisco
        if (storage.checkAuth()) {
          storage.clearStorage();
        }

        let par =
          "?userId=" +
          this.$route.query.userId +
          "&api_key=" +
          this.$route.query.api_key +
          "&origine=" +
          this.$route.query.origine;

        // Ricavo le credenziali
        let cred = await login_infoazienda(par);

        // Login
        await this.$store.dispatch("auth/login", cred.data);

        // Apro l'ordine
        let idPrev = parseInt(this.$route.query.idPrev);
        this.$store.commit("user/SET_IDPREV", idPrev);
        this.$router.push({ path: "/" + this.$route.query.origine });
      } catch (error) {
        console.log(error);
        this.alertElaborazione = this.$i2kFunctions.getErrorMsg(error);
      }

      this.attesaVerifica = false;
    },

    checkQueryStringCode() {
      if (
        this.$route.query.idPrev &&
        this.$route.query.idPrev.length > 0 &&
        this.$route.query.userId &&
        this.$route.query.userId.length > 0 &&
        this.$route.query.api_key &&
        this.$route.query.api_key.length > 0 &&
        this.$route.query.origine &&
        this.$route.query.origine.length > 0
      ) {
        return true;
      }

      return false;
    },
  },

  created() {
    this.verificaToken();
  },
};
</script>