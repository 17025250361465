<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    @keydown.esc="chiudi()"
    width="520"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark v-on:click="chiudi()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Modifica rigo</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text v-on:click="salva()" :disabled="attesa" :loading="attesa"
            >Salva
            <v-icon right>save</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-tabs v-model="tab">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab :key="1" style="font-weight: bold">{{
                  $store.getters["user/traduci"]("Descrizione")
                }}</v-tab>
                <v-tab :key="2" style="font-weight: bold"
                  >{{ $store.getters["user/traduci"]("Descrizione") }} FR</v-tab
                >
                <v-tab :key="3" style="font-weight: bold"
                  >{{ $store.getters["user/traduci"]("Descrizione") }} EN</v-tab
                >
              </v-tabs>

              <v-tabs-items v-model="tab" class="pt-2">
                <v-tab-item :key="1">
                  <v-textarea
                    label="Descrizione"
                    v-model="dati.Descrizione"
                    required
                    :rules="textRules"
                    outlined
                    no-resize
                  ></v-textarea>
                </v-tab-item>

                <v-tab-item :key="2">
                  <v-textarea
                    label="Descrizione"
                    v-model="dati.DescrizioneFR"
                    required
                    outlined
                    no-resize
                  ></v-textarea>
                </v-tab-item>

                <v-tab-item :key="3">
                  <v-textarea
                    label="Descrizione"
                    v-model="dati.DescrizioneEN"
                    required
                    outlined
                    no-resize
                  ></v-textarea>
                </v-tab-item>
              </v-tabs-items>
            </v-col>

            <v-col xs4 sm4 class="mr-2">
              <v-text-field
                :label="$store.getters['user/traduci']('Q.tà')"
                v-model="dati.Qta"
                outline
                :rules="numericRules"
                mask="###"
                reverse
                type="number"
              ></v-text-field>
            </v-col>

            <v-col xs4 sm4>
              <v-text-field
                :label="$store.getters['user/traduci']('Prezzo')"
                v-model="dati.Prezzo"
                outline
                :rules="numericRules"
                reverse
                type="number"
              ></v-text-field>
            </v-col>

            <v-col xs1 sm1>
              <v-text-field
                label="Um"
                v-model="dati.UMisura"
                disabled
                class="ml-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  get_varianteDettaglio,
  save_dettaglioVariantiModifica,
} from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    scheda: { type: Number, default: 0 },
    prog: { type: Number, default: 0 },
    progVariante: { type: Number, default: 0 },
  },

  data: () => ({
    dati: {},
    dati_empty: {
      Descrizione: "",
      DescrizioneFR: "",
      DescrizioneEN: "",
      Qta: 0,
      Prezzo: 0,
    },

    textRules: [(v) => !!v || "Il campo è obbligatorio"],
    numericRules: [(v) => (!!v || v === 0 ? true : "Il campo è obbligatorio")],

    tab: 0,

    attesa: false,
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      // NOTA: quando apro si attiva la validazione
      this.$nextTick(() => this.$refs.form.resetValidation());

      // Reset
      this.dati = Object.assign({}, this.dati_empty);
      this.tab = 0;

      if (this.dialog == false) return;

      this.attesa = true;

      try {
        let res = await get_varianteDettaglio(
          this.idPrev +
            "/" +
            this.scheda +
            "/" +
            this.prog +
            "/" +
            this.progVariante
        );

        this.dati = res.data;
      } catch (error) {
        console.log(error);
      }

      this.attesa = false;
    },

    async salva() {
      // Validazione
      if (this.$refs.form.validate() == false) {
        return;
      }

      this.attesa = true;

      try {
        let res = await save_dettaglioVariantiModifica(this.dati);

        this.chiudi(true);
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    chiudi(aggiorna) {
      this.$emit("chiudiDialog", aggiorna);
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },
};
</script>