<template src="./home.html"></template>

<script>
import { get_comunicazioni } from "@/api/api.app.js";

export default {
  data() {
    return {
      dati: []
    };
  },

  methods: {
    async caricaDati() {
      // Reset
      this.dati = [];

      try {
        let res = await get_comunicazioni();

        this.dati = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    }
  },

  mounted() {
    this.caricaDati();
  }
};
</script>

<style>
/* NOTA: non funziona con scoped */
.v-carousel__controls {
  background-color: transparent !important;
}
</style>


