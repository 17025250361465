import Vue from 'vue'
import Router from 'vue-router'

import Main from '@/views/Main'
import Login from '@/views/Login'
import StatoOrdini from '@/views/StatoOrdini'
import Home from '@/views/Home'
import Down from '@/views/Download'
import Profilo from '@/views/Profilo'
import Clienti from '@/views/Clienti'
import Preventivi from '@/views/Ordini'
import Ordini from '@/views/Ordini'
import OrdiniInviati from '@/views/OrdiniInviati'

import InfoAzienda from '@/views/app/InfoAzienda'
import QRCode from '@/views/app/qrcode/QRLaunch'
import QROrdine from "@/views/app/qrcode/QROrdine"
import QRProduzione from "@/views/app/qrcode/QRProduzione"


import storage from '@/services/storage.js'

Vue.use(Router)

const routerOptions = [{
  path: '/',
  component: Main,
  meta: {
    checkAuth: true
  },
  children: [{
    path: '/',
    component: Home,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/statoordini',
    component: StatoOrdini,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/download',
    component: Down,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/profilo',
    component: Profilo,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/clienti',
    component: Clienti,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/preventivi',
    component: Preventivi,
    meta: {
      checkAuth: true
    },
    name: "preventivi",
  },
  {
    path: '/ordini',
    component: Ordini,
    meta: {
      checkAuth: true,
      pathLink: "/ordiniinviati"
    },
    name: "ordini"
  },
  {
    path: '/ordiniinviati',
    component: OrdiniInviati,
    meta: {
      checkAuth: true
    },
    name: "ordiniinviati"
  },

  ]
},
{
  path: '/login',
  component: Login,
},

{
  path: '/app/infoazienda',
  component: InfoAzienda,
  name: "infoazienda"
},
{
  path: '/qr',
  component: QRCode,
  name: "qrcode"
},
{
  path: '/app/ordine/:token/:apiKey',
  component: QROrdine,
  name: "OrdineQr",
  props: true
},
{
  path: '/app/local/produzione/:apiKey/:token?',
  component: QRProduzione,
  name: "OrdineProduzione",
  props: true
},


{
  path: "*", // Pagine non trovate (redirect su /)

  beforeEnter: (to, from, next) => {
    next({
      path: '/'
    })
  }
}
]


const rou = new Router({
  mode: 'history',
  routes: routerOptions
})


rou.beforeEach((to, from, next) => {

  // Controllo se è richiesta l'autenticazione
  if (to.meta.checkAuth && !storage.checkAuth()) {

    next({
      path: '/login'
    })

    return

  }
  // Controllo pagina login
  else if (to.path == "/login" && storage.checkAuth()) {

    // Se il login è stato fatto, vado alla pagina riservata
    next({
      path: '/'
    })
    return
  }

  next();

})



export default rou;