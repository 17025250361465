<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    @keydown.esc="chiudi()"
    width="500"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark v-on:click="chiudi()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ getTitolo }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text v-on:click="invia()" :disabled="attesa" :loading="attesa"
            >Invia
            <v-icon right>send</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="pa-0"
                label="Da"
                outlined
                v-model="dati.Mittente"
                :rules="emailRules"
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                class="pa-0"
                label="A"
                outlined
                v-model="dati.Destinatario"
                :rules="emailRules"
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                class="pa-0"
                label="CC"
                outlined
                v-model="dati.CC"
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                class="pa-0"
                label="Oggetto"
                outlined
                v-model="dati.Oggetto"
                :rules="textRules"
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                class="pa-0"
                label="Testo"
                outlined
                v-model="dati.Testo"
                :rules="textRules"
                hide-details
              >
              </v-textarea>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="dati.Stampe"
                label="Tipo stampa"
                outlined
                hide-details
                item-value="CodStampa"
                item-text="Descrizione"
                v-model="dati.TipoStampa"
                :rules="textRules"
              >
              </v-select>
            </v-col>

            <v-col cols="12" class="py-0">
              <label class="file-select">
                <div class="select-button">
                  <span v-if="file">File da importare : {{ file.name }}</span>
                  <span v-else>Allega altri file ...</span>
                </div>
                <input type="file" @change="handleFileChange" ref="inputFile" />
              </label>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  get_mailOrdine,
  send_mailOrdine,
  upload_allegati,
  get_stampaCheckAvvisi,
} from "@/api/api.ordini.js";
export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
  },

  data: () => ({
    dati: {},
    dati_empty: {
      Mittente: 0,
      Destinatario: "",
      Oggetto: "",
      CC: "",
      Testo: "",
      NomeAllegati: [],
      TipoStampa: "",
      Stampa: [],
      StampaAvvisi: false,
    },

    tipoStampa: [],

    file: "",

    attesa: false,
    attesaInvio: false,

    textRules: [(v) => !!v || "Il campo è obbligatorio"],

    emailRules: [
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
        return pattern.test(value) || "Indirizzo email non valido.";
      },
    ],
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      // NOTA: quando apro si attiva la validazione
      this.$nextTick(() => this.$refs.form.resetValidation());

      // Reset
      Object.assign(this.dati, this.dati_empty);
      this.file = "";

      this.attesa = true;

      try {
        let res = await get_mailOrdine(this.idPrev);

        this.dati = res.data;
      } catch (error) {
        console.log(error);
      }

      this.attesa = false;
    },

    async invia() {
      // Validazione
      if (this.$refs.form.validate() == false) {
        return;
      }

      let confirm = await this.$confirm.open(
        "Sblocca",
        "Vuoi inviare la mail ?"
      );

      if (!confirm) return;

      this.attesa = true;
      this.attesaInvio = true;

      try {
        // Controllo avvisi
        let resAvvisi = await get_stampaCheckAvvisi(this.idPrev);
        let avvisi = resAvvisi.data;
        if (avvisi) {
          avvisi = await this.$confirm.open(
            "Stampa avvisi",
            "Sono presenti avvisi, vuoi stamparli  ?"
          );
        }
        this.dati.StampaAvvisi = avvisi;

        // Controllo presenza allegati
        if (this.$refs.inputFile.files.length > 0) {
          let formData = new FormData();
          formData.append("file", this.file);

          let resUpload = await upload_allegati(this.idPrev, formData);

          this.dati.NomeAllegati = new Array(this.file.name);
        }

        // Invia mail
        let res = await send_mailOrdine(this.idPrev, this.dati);
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
        console.log(error);
      }

      this.attesa = false;
      this.attesaInvio = false;

      this.chiudi(true);
    },

    handleFileChange(e) {
      // Whenever the file changes, emit the 'input' event with the file data.
      this.file = e.target.files[0];
    },

    chiudi(aggiorna) {
      this.$emit("chiudiDialog", aggiorna);
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getTitolo() {
      return this.attesaInvio ? "Invio in corso..." : "Invia E-Mail";
    },
  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },
};
</script>


<style scoped>
.file-select > .select-button {
  padding: 1rem;

  color: white;
  background-color: #2196f3;

  border-radius: 0.3rem;

  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

/* Don't forget to hide the original file input! */
.file-select > input[type="file"] {
  display: none;
}
</style>