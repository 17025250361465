export default {

  getToken() {
    return localStorage.getItem('token')
  },

  getRefToken() {
    return localStorage.getItem('ref_token')
  },

  getDataExpiredToken() {
    return localStorage.getItem('exp_data')
  },

  getAuthHeader() {
    return 'Bearer ' + this.getToken()
  },


  checkRemember() {
    return !!localStorage.getItem('ref_token')
  },

  checkAuth() {
    return !!localStorage.getItem('token')
  },



  getExpiration(tokenExpiration) {

    var now = new Date();
    now.setMinutes(now.getMinutes() + Math.ceil((tokenExpiration / 60)))

    return this.DateFormat(now);
  },


  DateFormat(now) {
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1);
    if (month.length == 1) {
      month = "0" + month;
    }
    let day = "" + now.getDate();
    if (day.length == 1) {
      day = "0" + day;
    }
    let hour = "" + now.getHours();
    if (hour.length == 1) {
      hour = "0" + hour;
    }
    let minute = "" + now.getMinutes();
    if (minute.length == 1) {
      minute = "0" + minute;
    }
    let second = "" + now.getSeconds();
    if (second.length == 1) {
      second = "0" + second;
    }

    return day + "/" + month + "/" + year + " " + hour + ":" + minute + ":" + second;
  },


  clearStorage() {

    localStorage.clear();
  },


  logout() {
    // Puliza storage
    this.clearStorage()

    // NOTA: vuex viene pulito perchè ricarica la pagina
    window.location = '/login'

  },

  setStorage(data) {

    localStorage.setItem('token', data.token);
    localStorage.setItem('exp_data', data.dataExpired)
    localStorage.setItem('ref_token', data.refToken)
  },

  setVersion(data) {
    localStorage.setItem('version', data);
  },

  getVersion() {
    return localStorage.getItem('version')
  }



}
