<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card color="white" dark width="300" class="mt-2">
        <v-img
          class="ma-2"
          :src="require('@/assets/img/logo_small.png')"
        ></v-img>

        <v-card-text
          class="title layout justify-center mt-4"
          v-show="attesaVerifica"
        >
          <!--  <div  class="black--text">Caricamento...</div> -->
          <v-progress-circular
            :size="40"
            color="#020D26"
            indeterminate
          ></v-progress-circular>
        </v-card-text>

        <v-card-text
          class="title layout justify-center"
          v-show="!attesaVerifica"
        >
          <div>
            <v-alert
              :value="!!alertElaborazione"
              :type="tipo"
              transition="scale-transition"
              style="width: 100%"
              v-html="alertElaborazione"
            ></v-alert>
          </div>

          <v-alert :value="!!errore" type="error">
            {{ errore }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { get_qrCodeDati } from "@/api/api.app.js";

export default {
  data: () => ({
    alertElaborazione: "",
    tipo: "success",
    attesaVerifica: false,

    errore: "",
  }),

  methods: {
    async verificaToken() {
      // Controllo querystring
      if (this.checkQueryStringCode() == false) {
        this.alertElaborazione = "Token non valido";
        this.tipo = "warning";
        return;
      }

      this.attesaVerifica = true;

      try {
        let id = this.$route.query.id;
        let apiKey = this.$route.query.ak;
        let app = this.$route.query.app;

        // Controllo app origine
        if (app === "1") {
          // ----------
          // Produzione interno
          // ----------

          // Controllo presenza numOrdine (nel caso di ricerca non c'è )
          if (id) {
            // Verifico i dati
            let res = await get_qrCodeDati(
              "local/numord/" + id + "?api_key=" + apiKey
            );
          }

          let idSwap = id || "";

          // Attendo per passaggio alla pagina successiva
          setTimeout(() => {
            this.$router.push({
              path: "/app/local/produzione/" + apiKey + "/" + idSwap,
            });
          }, 1000);
        } else if (app === "2") {
          // ----------
          // Ordine Internet clienti
          // ----------

          // Verifico i dati
          let res = await get_qrCodeDati("idprev/" + id + "?api_key=" + apiKey);

          // Attendo per passaggio alla pagina successiva
          setTimeout(() => {
            this.$router.push({
              path: "/app/ordine/" + id + "/" + apiKey,
            });
          }, 2000);
        }
      } catch (error) {
        this.attesaVerifica = false;
        console.log(error);

        this.errore = this.$i2kFunctions.getErrorMsg(error);
      }
    },

    checkQueryStringCode() {
      if (this.$route.query.ak && this.$route.query.ak.length > 0) {
        return true;
      }

      return false;
    },
  },

  mounted() {
    this.verificaToken();
  },
};
</script>