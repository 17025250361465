<template src="./ordiniInviati.html"></template>

<script>
import {
  get_listaOrdiniInviati,
  download_confermaOrdine,
} from "@/api/api.ordini.js";

import AutoCompleteClienti from "@/components/ui/AutoCompleteClienti";

export default {
  components: {
    AutoCompleteClienti,
  },

  data: function () {
    return {
      dati: [],
      documenti: [],

      attesa: false,

      headers: [
        { text: "Numero", value: "NumPrev" },
        { text: "Data invio", value: "DataInvioOrdine" },
        { text: "Numero Conferma", value: "NumOrdine" },
        { text: "Data Conferma", value: "DataOrdine" },
        { text: "Pdf Conferma", value: "" },
        { text: "Rag.Sociale", value: "RagSoc" },
        { text: "Riferimento", value: "Riferimento" },
        { text: "Elaborazione", value: "StatoElaborazione" },
        { text: "Tipo", value: "" },
        { text: "", value: "" },
      ],

      search: "",
      pagination: {
        rowsPerPage: 200,
      },
      sortBy: "NumPrev desc",
      sortDesc: false,

      filtroSel: "In attesa",
      filtriRicerca: ["In attesa", "Accettati", "Annullati"], //"Consegnati",
    };
  },

  methods: {
    async caricaDati() {
      // Reset
      this.dati = [];

      this.attesa = true;

      try {
        let res = await get_listaOrdiniInviati("");

        this.dati = res.data;
      } catch (error) {
        console.log(error);
      }

      this.attesa = false;
    },

    apriDoc(item) {
      this.$store.commit("user/SET_IDPREV", item.IDPrev);

      // Controllo ordine/preventivo
      if (item.Ordine) this.$router.push({ path: "/ordini" });
      else this.$router.push({ path: "/preventivi" });
    },

    getData(d) {
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },

    async pdfConferma(item) {
      try {
        let res = await download_confermaOrdine(item.IDPrev);

        this.$functionsApp.openPdfOnWeb(res.data);
      } catch (error) {
        console.log(error);
        this.$toast.alert("Conferma non trovata");
      }
    },

    nuovoOrdine() {
      this.$router.push({ path: "/ordini" });
    },

    changeSort(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    },

    abilitaApriDocumento(item) {
      if (this.utenteInterno) return true;

      // Se il prev non è ancora stato importato, ma solo inviato, possono modificare
      return (
        item.OrdineGenerato == false &&
        item.OrdineImportato == false &&
        item.OrdineInviato
      );
    },
  },

  mounted() {


    this.caricaDati();
  },

  computed: {
    role() {
      return this.$store.getters["user/getRole"]
    },

    getDati() {
      let res = this.dati;

      if (this.filtroSel == "In attesa")
        res = res.filter((f) => f.OrdineImportato == 0 && f.OrdineAnnullato == 0);
      else if (this.filtroSel == "Accettati")
        res = res.filter((f) => f.OrdineImportato == 1 && f.OrdineAnnullato == 0);
      else if (this.filtroSel == "Annullati")
        res = res.filter((f) => f.OrdineAnnullato == 1);
      else if (this.filtroSel == "Consegnati")
        return res;   // Da finire
      else res = [];
      return res;
    },

    getRole() {
      return this.$store.getters["user/getRole"];
    },

    utenteInterno() {
      return (
        this.getRole.toLowerCase() === "Interno".toLowerCase() ||
        this.getRole.toLowerCase() === "AgenteInterno".toLowerCase()
      );
    },
  },
};
</script>

<style scoped>
table.v-table thead th {
  white-space: normal !important;
  /*padding: 20px !important;*/
  text-align: center;
  padding: 0px !important;
  padding-left: 5px !important;
  background-color: #e0e0e0;
}

table.v-table td:not(:last-child) {
  border-right: 1px solid lightgray !important;
}

.v-datatable thead th.column.sortable {
  color: black;
}
</style>
