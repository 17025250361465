
import request from '@/services/interceptor.js'



// -----------------------------
// Download
// -----------------------------
export const get_datiDownload = () => {
    return request({
        url: '/download/dati',
        method: 'get',
        loadingBar: true
    })
};
export const get_datiDownloadFile = (par) => {
    return request({
        url: '/download/file?pathFile=' + par,
        method: 'get',
        responseType: 'blob',
        loadingBar: false
    })
};


