<template src="./cercaClienti.html"></template>

<script>
import { get_clientiOrdini, del_clientiOrdini } from "@/api/api.ordini.js";

import DialogInsCliente from "@/components/dialog/ordini/InsCliente";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    ordine: { type: Boolean, default: false },
    utilita: { type: Boolean, default: false },
  },
  components: {
    DialogInsCliente,
  },

  data: () => ({
    headers: [],
    selectedRow: [],

    filtriRicerca: ["RagSoc"],
    filtroSel: "Utente",

    dati: [{ NumPrev: "" }],
    loadingDati: false,

    search: "",

    dialogInsCliente: false,
    codClienteSel: 0,
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      // NOTA: non è impostatato in "data" per usare vuex
      this.headers = [
        {
          text: this.$store.getters["user/traduci"]("Cliente"),
          align: "left",
          value: "RagSoc",
        },
        {
          text: this.$store.getters["user/traduci"]("Localita"),
          align: "left",
          value: "Localita",
        },
      ];

      // Reset
      this.dati = [];
      this.selectedRow = [];
      this.codClienteSel = 0;

      this.loadingDati = true;
      try {
        let res = await get_clientiOrdini("");

        this.dati = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.loadingDati = false;
    },

    conferma() {
      // Controllo presenza righe
      if (this.controllaRigaSel() == false) {
        this.$toast.alert("Cliente non selezionato");
        return;
      }

      this.close(this.selectedRow.CodCliente);
    },

    nuovoCliente() {
      this.codClienteSel = 0;

      this.dialogInsCliente = true;
    },

    modificaCliente() {
      if (this.controllaRigaSel() == false) return;

      this.codClienteSel = this.selectedRow.CodCliente;
      this.dialogInsCliente = true;
    },

    async eliminaCliente() {
      if (this.controllaRigaSel() == false) return;

      try {
        let res = await del_clientiOrdini(this.selectedRow.CodCliente);
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.caricaDati();
    },

    controllaRigaSel() {
      // Controllo presenza righe
      return !(Object.keys(this.selectedRow).length == 0);
    },

    close(codCliente) {
      this.$emit("chiudiDialog", codCliente);
    },

    closeDialogInsCliente(aggiorna) {
      this.dialogInsCliente = false;

      if (aggiorna) this.caricaDati();
    },

    clickRow(item) {
      this.selectedRow = item;
    },
  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },

  computed: {
    getInterno() {
      return this.$store.getters["user/getRole"] == "Interno";
    },
  },
};
</script>
