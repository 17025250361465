import request from '@/services/interceptor.js'


// -----------------------------
// Auth
// -----------------------------
export const login_user = par => {
    return request({
        url: '/token',
        method: 'post',
        data: par,
        loadingBar: true
    })
}
export const logout_user = () => {
    return request({
        url: '/auth/logout',
        method: 'get'
    })
}
