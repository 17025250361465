<template>
  <v-dialog v-model="dialog" persistent width="500" @keydown.esc="close()">
    <v-card>
      <v-select
        class="py-4"
        style="max-width: 300px; margin: auto"
        label="Listino"
        :items="listini"
        v-model="listinoSel"
        :loading="attesa"
      ></v-select>

      <v-card-actions style="background-color: #e0e0e0">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.native="close()">Annulla</v-btn>
        <v-btn color="primary" @click.native="conferma()">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_listini, set_listino } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    codListino: { type: String, default: "" },
  },

  data: () => ({
    listini: [],
    listinoSel: "",

    attesa: false,
  }),

  methods: {
    async caricaDati() {
      if (this.dialog == false) return;

      // Reset
      this.listini = [];
      this.listinoSel = this.codListino;

      this.attesa = true;
      let res = await get_listini(this.idPrev);

      this.listini = res.data;

      this.attesa = false;
    },

    async conferma() {
      if (!!this.listinoSel == false) {
        this.$toast.alert("Listino non valido.");
        return;
      }

      let confirm = await this.$confirm.open(
        "Listino",
        "Confermi il cambio del listino attualmente applicato ?"
      );

      try {
        if (!confirm) return;

        let res = await set_listino(this.idPrev + "/" + this.listinoSel);

        this.close(true);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    close(aggiorna) {
      this.$emit("chiudiDialog", aggiorna);
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog() {
      this.caricaDati();
    },
  },
};
</script>