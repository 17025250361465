<template>
  <v-dialog v-model="dialog" persistent max-width="480" @keydown.esc="close()">
    <v-card class="py-1"  :loading="attesa"> 
      <v-card-title class="pa-1">
        <v-btn icon color="black" v-on:click="caricaDati()" class="mr-4">
          <v-icon>refresh</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="close()"><v-icon>close</v-icon></v-btn>
      </v-card-title>

      <v-timeline dense v-for="(item, i) in timeline" :key="i" style="max-width:90%;">
        <v-timeline-item color="primary" fill-dot small>
          <v-row class="pt-1">
            <v-col cols="4">
              <strong>{{ $i2kFunctions.formatISODateTime(item.Data) }}</strong>
            </v-col>
            <v-col>
              <strong> {{ item.Operazione }}</strong>
              <div class="text-caption">
                {{ item.Utente }}
              </div>
            </v-col>
          </v-row>

        </v-timeline-item>
      </v-timeline>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_timeLine } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
  },

  data: () => ({
    attesa: false,

    timeline: [],
  }),

  methods: {
    async caricaDati() {
      if (this.dialog == false) return;

      // Reset
      this.timeline = [];

      this.attesa = true;
      let res = await get_timeLine(this.idPrev);

      this.timeline = res.data;

      this.attesa = false;
    },

    close() {
      this.$emit("chiudiDialog");
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog() {
      this.caricaDati();
    },
  },
};
</script>