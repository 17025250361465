<template>
  <v-snackbar :timeout="-1" elevation="24" centered multi-line v-model="snack" :color="color">
    <v-icon class="mr-4">{{ icon }}</v-icon>
    <span style="font-size:16px; color: #fff;">{{ message }}</span>

    <template v-slot:action="{ attrs }">
      <v-btn outlined v-bind="attrs" @click="snack = false" rounded >
        Chiudi
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { EventBus } from "./toastEventBus";

export default {
  data: () => ({
    snack: false,
    message: "",
    timeOut: -1,
    color: "",
    isUpdate: false,
    par: {},
    icon: ''
  }),

  methods: {
    info(messaggio) {
      this.message = messaggio;
      this.color = "info";
      this.icon = 'info'
    },
    alert(messaggio) {
      this.message = messaggio;
      this.color = "warning";
      this.icon = 'warning'
    },

    error(messaggio) {
      this.message = messaggio;
      this.color = "error";
      this.icon = 'error'
    },

    success(messaggio) {
      this.message = messaggio;
      this.color = "success";
      this.icon = 'success'
    },



    handleToast(tipo, msg, _par) {
      this.snack = !!msg;

      // Merge  opzioni default con arg 
      this.par = { ...this.$toastOptions, ..._par };

      if (!this.snack) return;

      if (tipo == "alert") this.alert(msg);
      else if (tipo == "info") this.info(msg);
      else if (tipo == "error") this.error(msg);
      else if (tipo == "success") this.success(msg);
    },

    // Aggiorno l'app cancellando il nodo version dallo storage, e facendo la reload
    aggiorna() {
      localStorage.removeItem("version");
      window.location.reload();
    },
  },

  beforeMount() {
    EventBus.$on("ToastMessage", this.handleToast);
  },

  beforeDestroy() {
    EventBus.$off("ToastMessage", this.handleToast);
  },
};
</script>

